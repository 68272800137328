import React, {useEffect, useState} from "react";
import {useAbortController, useAppDispatch, useAppSelector} from "../../../hooks";
import {useParams} from "react-router-dom";
import {selectToken} from "../../../features/account/accountSlice";
import {Record} from "../../../models/Record";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import * as ErrorCodes from "../../../api/ErrorCodes";
import * as ApiHelper from "../../../api/ApiHelper";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {formatDateTime} from "../../../helpers/FormatHelper";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import NotFound from "../../Widgets/NotFound/NotFound";
import {LtEcgAttachmentsData} from "../../../models/LtEcgAttachmentsData";
import AsyncIndicatorWithText from "../../Widgets/AsyncIndicatorWithText/AsyncIndicatorWithText";
import {LtEcgRecordView} from "../../Widgets/LtEcgRecordView/LtEcgRecordView";
import {logError} from "../../../helpers/LogHelper";



export const LtEcgView: React.FC = () => {
    let {recordId = "@"} = useParams();
    const [controller] = useAbortController();
    const token = useAppSelector(selectToken);
    const dispatch = useAppDispatch();
    const [isFetching, setFetchingState] = useState(null as string | null | undefined);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [record, setRecord] = useState(null as Record | null);
    const [ltEcgAttachments, setLtEcgAttachments] = useState(undefined as LtEcgAttachmentsData | undefined | null);
    const recordHandler = (record: Record) => {
        setRecord(record);
        let ltEcgAttachments;
        try {
            ltEcgAttachments = JSON.parse(record.ltEcgStudy.attachments);
        } catch {
            ltEcgAttachments = null;
        }
        setLtEcgAttachments(ltEcgAttachments);
        setNotFoundState(false);
        setErrorState(!ltEcgAttachments);
        setFetchingState(undefined);
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Lt ecg data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
            setFetchingState(undefined);
        }
    };
    const fetchRecord = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(null);
            ApiHelper.getRecord(userToken, recordId, controller, recordHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchRecord(), [recordId]);     // eslint-disable-line

    const isOk = !hasError && !notFound && isFetching === undefined;
    return (
        <div>
            <Breadcrumbs
                data={new Map([[recordId, record ? formatDateTime(record.dateTime) : null], [record?.patientId ?? "_", record?.patientName ?? null]])}/>
            {isFetching !== undefined && <AsyncIndicatorWithText text={isFetching}/>}
            {isFetching === undefined && hasError && <FetchError onRetry={fetchRecord}/>}
            {isFetching === undefined && notFound && <NotFound/>}
            {(isOk && record && ltEcgAttachments) &&
                <LtEcgRecordView record={record} ltEcgAttachments={ltEcgAttachments}/>
            }
        </div>
    );
};

export default LtEcgView;