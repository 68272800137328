import React, {useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {selectToken} from "../../../features/account/accountSlice";
import {useAbortController, useAccessInfo, useAppDispatch, useAppSelector} from "../../../hooks";
import ErrorResponse from "../../../models/ErrorResponse";
import {Record} from '../../../models/Record';
import * as ApiHelper from '../../../api/ApiHelper';
import {formatDateTime} from "../../../helpers/FormatHelper";
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import handleErrors from "../../../helpers/ErrorHandler";
import {Role} from "../../../models/Role";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {Button} from "../../Widgets/Button/Button";
import {DialogConfirmation} from "../../Widgets/DialogConfirmation/DialogConfirmation";
import {Toast} from "../../Widgets/Toast/Toast";
import * as ErrorCodes from "../../../api/ErrorCodes";
import NotFound from "../../Widgets/NotFound/NotFound";
import {useTranslation} from "react-i18next";
import {RecordDetailsTable} from "../../Widgets/RecordDetailsTable/RecordDetailsTable";
import {AttachmentsTable} from "../../Widgets/AttachmentsTable/AttachmentsTable";
import {Attachment} from "../../../models/Attachment";
import {LtEcgAttachmentsData} from "../../../models/LtEcgAttachmentsData";
import {PdfViewer} from "../../Widgets/PdfViewer/PdfViewer";
import {isLtEcgDataFile, isPdfFile} from "../../../helpers/AttachmentHelper";
import {logError} from "../../../helpers/LogHelper";

interface RecordDetailsProps {
    canShare: boolean;
}

export const RecordDetails: React.FC<RecordDetailsProps> = ({canShare}: RecordDetailsProps) => {
    const {t} = useTranslation();
    let {recordId = "@"} = useParams();
    const [controller] = useAbortController();
    const history = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const isSupport = useAccessInfo(Role.Support);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [hasDeleteError, setDeleteErrorState] = useState(false);
    const [hasDownloadError, setDownloadErrorState] = useState(false);
    const [record, setRecord] = useState(null as Record | null);
    const [attachments, setAttachments] = useState(null as Array<Attachment> | null);
    const [ltEcgAttachments, setLtEcgAttachments] = useState(null as LtEcgAttachmentsData | null);
    const pdfAttachment = useMemo(() => ltEcgAttachments?.attachments.find(a => isPdfFile(a.fileName)), [ltEcgAttachments]);
    const sigAttachment = useMemo(() => ltEcgAttachments?.attachments.find(a => isLtEcgDataFile(a.fileName)), [ltEcgAttachments]);
    const handleLtEcgViewClick = useMemo(() => {
        if (sigAttachment) {
            return () => {
                history(`${location.pathname}/lt-ecg`);
            };
        } else {
            return undefined;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sigAttachment]);

    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const recordHandler = (record: Record) => {
        setFetchingState(false);
        setErrorState(false);
        setNotFoundState(false);
        setRecord(record);
        try {
            const attachments: Array<Attachment> = JSON.parse(record.state.attachments);
            setAttachments(attachments);
        } catch {
            setAttachments(null);
        }
        try {
            const ltEcgAttachments: LtEcgAttachmentsData = JSON.parse(record.ltEcgStudy.attachments);
            setLtEcgAttachments(ltEcgAttachments);
        } catch {
            setLtEcgAttachments(null);
        }
    };
    const deleteHandler = () => {
        setErrorState(false);
        setNotFoundState(false);
        setDeleteErrorState(false);
        setFetchingState(false);
        history(-1);
    }
    const errorHandler = (error: ErrorResponse) => {
        logError("Record data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
            setFetchingState(false);
        }
    };
    const deleteErrorHandler = (error: ErrorResponse) => {
        logError("Record data delete error", error);
        if (!handleErrors(error, dispatch)) {
            setDeleteErrorState(false);
            setDeleteErrorState(true);
            setFetchingState(false);
        }
    };
    const downloadErrorHandler = (error: ErrorResponse) => {
        logError("Record data download error", error);
        if (!handleErrors(error, dispatch)) {
            setDownloadErrorState(false);
            setDownloadErrorState(true);
            setFetchingState(false);
        }
    };
    const fetchRecord = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getRecord(userToken, recordId, controller, recordHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchRecord(), [recordId]);     // eslint-disable-line
    const deleteRecord = () => {
        setShowDeleteConfirmationDialog(false);
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.deleteRecord(userToken, recordId, controller, deleteHandler, deleteErrorHandler)
        }
    };
    const handleViewEcgClick = () => {
        if (record && record.ecgStudy && record.ecgStudy.ecgId) {
            history(`${location.pathname}/ecg`);
        }
    };
    const handleViewSpiroClick = () => {
        if (record && record.spiroStudy && record.spiroStudy.spiroId) {
            history(`${location.pathname}/spiro`);
        }
    };
    const handleViewStethoscopeClick = () => {
        if (record && record.stethoscopeStudy && record.stethoscopeStudy.stethoscopeId) {
            history(`${location.pathname}/stethoscope`);
        }
    };
    const downloadStudy = (studyId: string | undefined) => {
        let userToken = token?.token;
        if (userToken && record && studyId) {
            ApiHelper.getZipStudy(userToken, record.id, studyId, controller, () => {
            }, downloadErrorHandler);
        }
    }
    const handleDownloadEcgClick = () => {
        downloadStudy(record?.ecgStudy?.ecgId);
    };
    const handleDownloadSpiroClick = () => {
        downloadStudy(record?.spiroStudy?.spiroId);
    };
    const handleDownloadStethoscopeClick = () => {
        downloadStudy(record?.stethoscopeStudy?.stethoscopeId);
    };
    const accessControlHandler = () => {
        history(`${location.pathname}/share`);
    }
    const isOk = !hasError && !notFound && !isFetching;
    return (
        <div>
            <Breadcrumbs
                data={new Map([[recordId, record ? formatDateTime(record.dateTime) : null], [record?.patientId ?? "_", record?.patientName ?? null]])}/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchRecord}/>}
            {!isFetching && notFound && <NotFound/>}
            {(isOk && record && ltEcgAttachments && ltEcgAttachments.attachments.length > 0 && pdfAttachment) &&
                <PdfViewer url={pdfAttachment.link}/>
            }
            {(isOk && record) &&
                <RecordDetailsTable record={record} isSupport={isSupport}
                                    handleViewEcgClick={handleViewEcgClick}
                                    handleDownloadEcgClick={handleDownloadEcgClick}
                                    handleViewSpiroClick={handleViewSpiroClick}
                                    handleDownloadSpiroClick={handleDownloadSpiroClick}
                                    handleViewStethoscopeClick={handleViewStethoscopeClick}
                                    handleDownloadStethoscopeClick={handleDownloadStethoscopeClick}
                                    handleViewLtEcgClick={handleLtEcgViewClick}/>
            }
            {(isOk && record && attachments && attachments.length > 0) &&
                <AttachmentsTable attachments={attachments}/>
            }
            {(isOk && record && ltEcgAttachments && ltEcgAttachments.attachments.length > 0) &&
                <AttachmentsTable attachments={ltEcgAttachments.attachments.map(a => {
                    return {
                        fileId: a.uid,
                        fileName: a.fileName,
                        cloudUrl: a.link
                    };
                })}/>
            }
            {(isOk && record && canShare) &&
                <div className="d-flex justify-content-center">
                    <Button className="mx-2" text={t("access_control")} onClick={accessControlHandler}/>
                </div>
            }
            {(isOk && record && isSupport) &&
                <div className="d-flex justify-content-center">
                    <div className="details-controls d-flex justify-content-around my-2">
                        <Button text={t("delete")} danger={true} onClick={() => {
                            setShowDeleteConfirmationDialog(true)
                        }}/>
                    </div>
                </div>
            }
            {showDeleteConfirmationDialog &&
                <DialogConfirmation titleText={t("delete_record_title")} messageText={t("delete_record_description")}
                                    okButtonText={t("delete")} cancelButtonText={t("cancel")}
                                    okButtonClickHandler={deleteRecord}
                                    cancelButtonClickHandler={() => setShowDeleteConfirmationDialog(false)}/>}
            {hasDeleteError &&
                <Toast text={t("connection_problem")} isError={true}/>
            }
            {hasDownloadError &&
                <Toast text={t("error_downloading_data")} isError={true}/>
            }
        </div>
    );
};

export default RecordDetails;