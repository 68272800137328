import React from "react";
import {getUserPhotoUrl} from "../../../api/ApiHelper";
import './RecordAccess.css';
import {useTranslation} from "react-i18next";
import {RecordAccessData} from "../../../models/RecordAccessData";
import {formatDateTime, formatPatientName} from "../../../helpers/FormatHelper";
import {Avatar} from "@mui/material";
import {Code} from "@mui/icons-material";

interface RecordAccessProps {
    data: RecordAccessData;
    isOwn: boolean;
    onClick: (id: string) => void;
}

const RecordAccess: React.FC<RecordAccessProps> = ({data, isOwn, onClick}: RecordAccessProps) => {
    const {t} = useTranslation();
    const photoUrl = getUserPhotoUrl(isOwn ? data.holderPhotoUrl : data.ownerPhotoUrl);
    return (
        <tr onClick={() => onClick(data.id)}>
            <td align="center" className="d-flex justify-content-center align-items-center"><Avatar
                className="user-avatar-img mx-2" alt={isOwn ? data.holderName : data.ownerName} src={photoUrl}/>{isOwn ? data.holderName : data.ownerName}</td>
            <td align="center">{isOwn ? data.holderEmail : data.ownerEmail}</td>
            <td align="center">{formatPatientName(t, data.patientId === null, data.patientName)}</td>
            <td align="center">{formatDateTime(data.recordDateTime)}</td>
            <td align="center"><div className={"type-icon"}><Code/><span>{t("share_link")}</span></div></td>
        </tr>
    );
}

export default RecordAccess;