import Footer from "../../Widgets/Footer/Footer";
import React, {useEffect, useMemo, useState} from "react";
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import NotFound from "../../Widgets/NotFound/NotFound";
import {RecordDetailsTable} from "../../Widgets/RecordDetailsTable/RecordDetailsTable";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useAbortController, useAccessInfo, useAppDispatch, useAppSelector} from "../../../hooks";
import {Record} from "../../../models/Record";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import * as ErrorCodes from "../../../api/ErrorCodes";
import * as ApiHelper from "../../../api/ApiHelper";
import {formatDateTime} from "../../../helpers/FormatHelper";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {Role} from "../../../models/Role";
import {selectToken} from "../../../features/account/accountSlice";
import {Toast} from "../../Widgets/Toast/Toast";
import {useTranslation} from "react-i18next";
import {AttachmentsTable} from "../../Widgets/AttachmentsTable/AttachmentsTable";
import {Attachment} from "../../../models/Attachment";
import {LtEcgAttachmentsData} from "../../../models/LtEcgAttachmentsData";
import {PdfViewer} from "../../Widgets/PdfViewer/PdfViewer";
import {isPdfFile} from "../../../helpers/AttachmentHelper";
import {logError} from "../../../helpers/LogHelper";


export function SharedRecordDetails() {
    const {t} = useTranslation();
    let {recordToken = ""} = useParams();
    const [controller] = useAbortController();
    const history = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const isSupport = useAccessInfo(Role.Support);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [record, setRecord] = useState(null as Record | null);
    const [attachments, setAttachments] = useState(null as Array<Attachment> | null);
    const [ltEcgAttachments, setLtEcgAttachments] = useState(null as LtEcgAttachmentsData | null);
    const pdfAttachment = useMemo(() => ltEcgAttachments?.attachments.find(a => isPdfFile(a.fileName)), [ltEcgAttachments]);
    const [hasDownloadError, setDownloadErrorState] = useState(false);
    const recordHandler = (record: Record) => {
        setFetchingState(false);
        setErrorState(false);
        setNotFoundState(false);
        setRecord(record);
        try {
            const attachments: Array<Attachment> = JSON.parse(record.state.attachments);
            setAttachments(attachments);
        } catch {
            setAttachments(null);
        }
        try {
            const ltEcgAttachments: LtEcgAttachmentsData = JSON.parse(record.ltEcgStudy.attachments);
            setLtEcgAttachments(ltEcgAttachments);
        } catch {
            setLtEcgAttachments(null);
        }
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Record data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
            setFetchingState(false);
        }
    };
    const downloadErrorHandler = (error: ErrorResponse) => {
        logError("Record data download error", error);
        if (!handleErrors(error, dispatch)) {
            setDownloadErrorState(false);
            setDownloadErrorState(true);
            setFetchingState(false);
        }
    };
    const fetchRecord = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getRecordWithToken(recordToken, controller, recordHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchRecord(), [token]);     // eslint-disable-line
    const handleViewEcgClick = () => {
        if (record && record.ecgStudy && record.ecgStudy.ecgId) {
            history(`${location.pathname}/ecg`);
        }
    };
    const handleViewSpiroClick = () => {
        if (record && record.spiroStudy && record.spiroStudy.spiroId) {
            history(`${location.pathname}/spiro`);
        }
    };
    const handleViewStethoscopeClick = () => {
        if (record && record.stethoscopeStudy && record.stethoscopeStudy.stethoscopeId) {
            history(`${location.pathname}/stethoscope`);
        }
    };
    const downloadStudy = (studyId: string | undefined) => {
        let userToken = token?.token;
        if (userToken && record && studyId) {
            ApiHelper.getZipStudy(userToken, record.id, studyId, controller, () => {
            }, downloadErrorHandler);
        }
    }
    const handleDownloadEcgClick = () => {
        downloadStudy(record?.ecgStudy?.ecgId);
    };
    const handleDownloadSpiroClick = () => {
        downloadStudy(record?.spiroStudy?.spiroId);
    };
    const handleDownloadStethoscopeClick = () => {
        downloadStudy(record?.stethoscopeStudy?.stethoscopeId);
    };
    const isOk = !hasError && !notFound && !isFetching;
    return (
        <div>
            <Breadcrumbs
                data={new Map([[record?.id ?? "_", record ? formatDateTime(record.dateTime) : null], [record?.patientId ?? "_", record?.patientName ?? null]])}/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchRecord}/>}
            {!isFetching && notFound && <NotFound/>}
            {(isOk && record && ltEcgAttachments && ltEcgAttachments.attachments.length > 0 && pdfAttachment) &&
                <PdfViewer url={pdfAttachment.link}/>
            }
            {(isOk && record) &&
                <RecordDetailsTable record={record} isSupport={isSupport}
                                    handleViewEcgClick={handleViewEcgClick}
                                    handleDownloadEcgClick={handleDownloadEcgClick}
                                    handleViewSpiroClick={handleViewSpiroClick}
                                    handleDownloadSpiroClick={handleDownloadSpiroClick}
                                    handleViewStethoscopeClick={handleViewStethoscopeClick}
                                    handleDownloadStethoscopeClick={handleDownloadStethoscopeClick}/>
            }
            {(isOk && record && attachments && attachments.length > 0) &&
                <AttachmentsTable attachments={attachments}/>
            }
            {(isOk && record && ltEcgAttachments && ltEcgAttachments.attachments.length > 0) &&
                <AttachmentsTable attachments={ltEcgAttachments.attachments.map(a => {
                    return {
                        fileId: a.uid,
                        fileName: a.fileName,
                        cloudUrl: a.link
                    };
                })}/>
            }
            {hasDownloadError &&
                <Toast text={t("error_downloading_data")} isError={true}/>
            }
            <Footer/>
        </div>
    );
}