import React from "react";
import {useState} from "react";
import SvgEditDateIcon from "../../../assets/svg/EditDateIcon";
import {formatBirthdate} from "../../../helpers/FormatHelper";
import './BirthDatePicker.css';
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment";

interface DatePickerProps {
    date: Date;
    onChange: (date: Date) => void;
    disabled: boolean;
    className?: string;
}

export const BirthDatePicker: React.FC<DatePickerProps> = ({
                                                               date,
                                                               onChange,
                                                               disabled,
                                                               className = ""
                                                           }: DatePickerProps) => {
    const [isOpen, setOpen] = useState(false);
    const handleClick = () => {
        if (!disabled) {
            setOpen(true);
        }
    }
    //TODO upgrage
    const datePickerChangeHandler = (v: any) => {
        const d: Date | null = v?.toDate();
        if (d) {
            onChange(d);
        }
    };
    return (
        <div className="birth-date-picker-container">
            <div className={`${className} d-flex justify-content-between align-items-center`} onClick={handleClick}>
                <div>{formatBirthdate(date)}</div>
                <SvgEditDateIcon className={`${disabled ? "hidden" : ""}`}/>
            </div>
            <div style={{position: "relative", width: "1px", height: "1px", top: "-45px", visibility: "hidden"}}>
                <DatePicker
                    open={isOpen}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    disableFuture value={moment(date)}
                    onChange={datePickerChangeHandler}/>
            </div>
        </div>
    );
}