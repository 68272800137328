import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import languageEn from './locale/en/translate.json';
import languageRu from './locale/ru/translate.json';
import languageUk from './locale/uk/translate.json';
import languagePl from './locale/pl/translate.json';
import {initReactI18next} from "react-i18next";

i18n.use(LanguageDetector)
    .init({
    resources: {
        en: languageEn,
        uk: languageUk,
        pl: languagePl,
        ru: languageRu
    },
    fallbackLng: "en",
    debug: true,

    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, 

    interpolation: {
        escapeValue: false, 
        formatSeparator: ","
    }
});

export default i18n;
