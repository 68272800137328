import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {Button} from "../../Widgets/Button/Button";
import {DialogConfirmation} from "../../Widgets/DialogConfirmation/DialogConfirmation";
import {UserAccessData} from "../../../models/UserAccessData";
import * as ApiHelper from "../../../api/ApiHelper";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import * as ErrorCodes from "../../../api/ErrorCodes";
import {useAbortController, useAppDispatch, useAppSelector} from "../../../hooks";
import {selectToken} from "../../../features/account/accountSlice";
import {Record} from "../../../models/Record";
import {formatDateTime} from "../../../helpers/FormatHelper";
import {Patient} from "../../../models/Patient";
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import NotFound from "../../Widgets/NotFound/NotFound";
import {ShareType} from "../../../models/ShareType";
import {OwnerUserInfo} from "../../Widgets/OwnerUserInfo/OwnerUserInfo";
import {useNavigate} from "react-router-dom";
import {logError} from "../../../helpers/LogHelper";

interface AccessControlSharedDataProps {
    isAppointment: boolean;
}

export const AccessControlSharedData: React.FC<AccessControlSharedDataProps> = ({isAppointment}: AccessControlSharedDataProps) => {
    const {t} = useTranslation();
    const history = useNavigate();
    const [controller] = useAbortController();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    let {patientId = "@", recordId = "@"} = useParams();
    const [user, setUser] = useState(null as UserAccessData | null);
    const [record, setRecord] = useState(null as Record | null);
    const [patient, setPatient] = useState(null as Patient | null);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const recordHandler = (record: Record) => {
        let userToken = token?.token;
        if (userToken) {
            ApiHelper.getRecordOwner(userToken, recordId, controller, userHandler, errorHandler);
        }
        setRecord(record);
    };
    const patientHandler = (patient: Patient) => {
        let userToken = token?.token;
        if (userToken) {
            ApiHelper.getPatientOwner(userToken, patientId, controller, userHandler, errorHandler);
        }
        setPatient(patient);
    };
    const userHandler = (user: UserAccessData) => {
        setFetchingState(false);
        setErrorState(false);
        setUser(user);
    };
    const refuseHandler = () => {
        setFetchingState(false);
        history(-1);
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Access data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
            setFetchingState(false);
        }
    };
    const fetchRecord = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getRecord(userToken, recordId, controller, recordHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    const fetchPatient = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getPatient(userToken, patientId, controller, patientHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    const fetchData = () => {
        if (recordId) {
            fetchRecord();
        } else {
            if (patientId) {
                fetchPatient();
            }
        }
    }
    useEffect(() => {
        fetchData();
    }, [recordId, patientId]);     // eslint-disable-line
    const refuseAccessClick = () => {
        setShowDeleteConfirmationDialog(true)
    }
    const refuseAccess = () => {
        setShowDeleteConfirmationDialog(false);
        let userToken = token?.token;
        let dataId = user?.id;
        if (userToken) {
            setFetchingState(true);
            if (user?.type === ShareType.Association){
                ApiHelper.refuseAssociatedUser(userToken, user?.email ?? "", controller, refuseHandler, errorHandler);
            } else {
                if (dataId) {
                    if (isAppointment) {
                        ApiHelper.refuseRecordShare(userToken, [dataId], controller, refuseHandler, errorHandler);
                    } else {
                        ApiHelper.refusePatientShare(userToken, [dataId], controller, refuseHandler, errorHandler);
                    }
                }
            }
        }
    };
    const isOk = !hasError && !notFound && !isFetching;
    return (
        <div>
            <Breadcrumbs
                data={new Map([[recordId ?? "_1", record ? formatDateTime(record.dateTime) : null], [patientId ?? "_2", record ? (record.patientName ?? null) : (patient?.name ?? null)]])}/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchData}/>}
            {!isFetching && notFound && <NotFound/>}
            {isOk && user &&
            <div className="d-flex flex-column align-items-center">
                <OwnerUserInfo user={user}/>
                <Button className="mr-2 my-4" text={t("refuse_access")}
                        highlighted={true}
                        onClick={refuseAccessClick}/>
            </div>
            }
            {showDeleteConfirmationDialog &&
            <DialogConfirmation titleText={t("refuse_access")} messageText={t(user?.type === ShareType.Link ? "confirm_access_refuse" : "confirm_association_refuse", {
                name: user?.name ?? "",
                email: user?.email ?? ""
            })}
                                okButtonText={t("yes")} cancelButtonText={t("no")}
                                okButtonClickHandler={refuseAccess}
                                cancelButtonClickHandler={() => setShowDeleteConfirmationDialog(false)}/>}
        </div>
    );
}