import './Settings.css';
import {useTranslation} from "react-i18next";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import React, {useState} from "react";
import LanguageDropdown from "../../Widgets/LanguageDropdown/LanguageDropdown";
import ColorSchemeDropdown from "../../Widgets/ColorSchemeDropdown/ColorSchemeDropdown";
import {EcgMvScaleDropdown} from "../../Widgets/EcgMvScaleDropdown/EcgMvScaleDropdown";
import {EcgTimeScaleDropdown} from "../../Widgets/EcgTimeScaleDropdown/EcgTimeScaleDropdown";
import {ReportGridColorDropdown} from "../../Widgets/ReportGridColorDropdown/ReportGridColorDropdown";
import {StethoscopeTimeScaleDropdown} from "../../Widgets/StethoscopeTimeScaleDropdown/StethoscopeTimeScaleDropdown";
import {
    ECG_MV_SCALE_INDEX_DEFAULT_VALUE,
    ECG_MV_SCALE_SETTINGS_KEY,
    ECG_REPORT_GRID_INDEX_DEFAULT_VALUE,
    ECG_REPORT_GRID_SETTINGS_KEY,
    ECG_TIME_SCALE_INDEX_DEFAULT_VALUE,
    ECG_TIME_SCALE_SETTINGS_KEY,
    STETHOSCOPE_TIME_SCALE_INDEX_DEFAULT_VALUE,
    STETHOSCOPE_TIME_SCALE_SETTINGS_KEY,
    usePersistentState
} from "../../../persistance/PersistanceHelper";
import {useNavigate} from "react-router-dom";
import {ACCESS_CONTROL_ROUTE, NOTIFICATION_SETTINGS_ROUTE} from "../../../routes";
import {DialogConfirmAccountDelete} from "../../Widgets/DialogConfirmAccountDelete/DialogConfirmAccountDelete";
import {useAbortController, useAppSelector} from "../../../hooks";
import {selectToken} from "../../../features/account/accountSlice";
import {KeyboardArrowRight} from "@mui/icons-material";

export function Settings() {
    const {t} = useTranslation();
    const [controller] = useAbortController();
    const token = useAppSelector(selectToken);
    const history = useNavigate();
    const [ecgMvScale, setEcgMvScale] = usePersistentState(ECG_MV_SCALE_SETTINGS_KEY, ECG_MV_SCALE_INDEX_DEFAULT_VALUE);
    const [ecgTimeScale, setEcgTimeScale] = usePersistentState(ECG_TIME_SCALE_SETTINGS_KEY, ECG_TIME_SCALE_INDEX_DEFAULT_VALUE);
    const [ecgReportGridColor, setEcgReportGridColor] = usePersistentState(ECG_REPORT_GRID_SETTINGS_KEY, ECG_REPORT_GRID_INDEX_DEFAULT_VALUE);
    const [stethoscopeTimeScale, setStethoscopeTimeScale] = usePersistentState(STETHOSCOPE_TIME_SCALE_SETTINGS_KEY, STETHOSCOPE_TIME_SCALE_INDEX_DEFAULT_VALUE);
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const onAccessControlClick = () => {
        history(ACCESS_CONTROL_ROUTE);
    };
    const onNotificationSettingsClick = () => {
        history(NOTIFICATION_SETTINGS_ROUTE);
    };
    const  onDeleteAccountClick = () => {
        setShowDeleteConfirmationDialog(true);
    };
    return (
        <div>
            <Breadcrumbs/>
            <div className="d-flex flex-column align-items-center">
                <div className="details-table my-2">
                    <div className="settings-title disable-select">{t("general_settings")}</div>
                    <table>
                        <tbody>
                        <tr>
                            <th>{t("language")}</th>
                            <td><LanguageDropdown className="input-dropdown"/></td>
                        </tr>
                        <tr>
                            <th>{t("color_scheme")}</th>
                            <td><ColorSchemeDropdown className="input-dropdown"/></td>
                        </tr>
                        <tr>
                            <th>{t("access_control")}</th>
                            <td>
                                <div className="d-flex justify-content-between align-items-center input-text link"
                                     onClick={onAccessControlClick}>{t("manage")}<KeyboardArrowRight/></div>
                            </td>
                        </tr>
                        <tr>
                            <th>{t("notification_settings")}</th>
                            <td>
                                <div className="d-flex justify-content-between align-items-center input-text link"
                                     onClick={onNotificationSettingsClick}>{t("manage")}<KeyboardArrowRight/></div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="details-table my-2">
                    <div className="settings-title disable-select">{t("ecg")}</div>
                    <table>
                        <tbody>
                        <tr>
                            <th>{t("default_amplitude_scale")}</th>
                            <td><EcgMvScaleDropdown className="input-dropdown" initialValue={ecgMvScale}
                                                    onChange={setEcgMvScale}/></td>
                        </tr>
                        <tr>
                            <th>{t("default_time_scale")}</th>
                            <td><EcgTimeScaleDropdown className="input-dropdown" initialValue={ecgTimeScale}
                                                      onChange={setEcgTimeScale}/></td>
                        </tr>
                        <tr>
                            <th>{t("report_grid_color")}</th>
                            <td><ReportGridColorDropdown className="input-dropdown"
                                                         initialValue={ecgReportGridColor}
                                                         onChange={setEcgReportGridColor}/></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="details-table my-2">
                    <div className="settings-title disable-select">{t("stethoscope")}</div>
                    <table>
                        <tbody>
                        <tr>
                            <th>{t("default_time_scale")}</th>
                            <td><StethoscopeTimeScaleDropdown className="input-dropdown"
                                                              initialValue={stethoscopeTimeScale}
                                                              onChange={setStethoscopeTimeScale}/></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="details-table my-2">
                    <div className="settings-title disable-select">{t("personal_data")}</div>
                    <table>
                        <tbody>
                        <tr>
                            <th>{t("account_deletion")}</th>
                            <td>
                                <div className="d-flex justify-content-between align-items-center input-text link"
                                     onClick={onDeleteAccountClick}>{t("delete")}<KeyboardArrowRight/></div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {showDeleteConfirmationDialog &&
                <DialogConfirmAccountDelete token={token?.token ?? ""} controller={controller}
                                            closeHandler={() => setShowDeleteConfirmationDialog(false)}/>}
        </div>
    );
}