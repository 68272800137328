import {useAbortController, useAppDispatch} from "../../../hooks";
import {useNavigate, useLocation} from "react-router";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Record} from "../../../models/Record";
import {SpiroData} from "../../../models/SpiroData";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import * as ErrorCodes from "../../../api/ErrorCodes";
import * as ApiHelper from "../../../api/ApiHelper";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {formatDateTime} from "../../../helpers/FormatHelper";
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import NotFound from "../../Widgets/NotFound/NotFound";
import {Conclusion} from "../../Widgets/Conclusion/Conclusion";
import {SpiroDetailsTable} from "../../Widgets/SpiroDetailsTable/SpiroDetailsTable";
import {spiroTestData} from "../../../spr/SpiroTest";
import {SpiroViewer} from "../../Widgets/SpiroViewer/SpiroViewer";
import StarIcon from "../../../assets/svg/StarIcon";
import {PatientInfo} from "../../Widgets/PatientInfo/PatientInfo";
import {logError} from "../../../helpers/LogHelper";


export const GuestSpiroView: React.FC = () => {
    const history = useNavigate();
    const location = useLocation();
    const {token = "", tabIndex } = useParams();
    const activeTabIndex = Number(tabIndex ?? "0") ?? 0;
    const setActiveTabIndex = (index: number) => {
        const newLocation = location.pathname.replace(/\d+$/gm, `${index}`);
        history(newLocation, {replace: true});
    }
    const [controller] = useAbortController();
    const dispatch = useAppDispatch();
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [record, setRecord] = useState(null as Record | null);
    const [initialConclusion, setInitialConclusion] = useState(null as null | string);
    const [data, setData] = useState(null as SpiroData | null);
    const dataHandler = (data: SpiroData) => {
        setFetchingState(false);
        setErrorState(false);
        setNotFoundState(false);
        setActiveTabIndex(0);
        setData(data);
    };
    const recordHandler = (record: Record) => {
        setErrorState(false);
        setNotFoundState(false);
        setRecord(record);
        setInitialConclusion(record.spiroStudy.spiroConclusion ?? "");
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Spiro data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
            setFetchingState(false);
        }
    };
    const fetchData = (record: Record) => {
        if (process.env.NODE_ENV === 'development') {
            //For testing
            dataHandler(spiroTestData);
        } else {
            //Main code
            if (record.spiroStudy) {
                setFetchingState(true);
                ApiHelper.getSpiroDataWithToken(token, record.spiroStudy.spiroId, controller, dataHandler, errorHandler);
            } else {
                setErrorState(true);
            }
        }
    };
    const fetchRecord = () => {
        setFetchingState(true);
        ApiHelper.getRecordWithToken(token, controller, recordHandler, errorHandler);
    };
    useEffect(() => fetchRecord(), [token]);     // eslint-disable-line
    useEffect(() => {
        if (record) {
            fetchData(record);
        }
    }, [record]);     // eslint-disable-line
    const isOk = !hasError && !notFound && !isFetching;
    const tabButtonClickHandler: (index: number) => void = (index) => {
        if (index !== activeTabIndex) {
            setActiveTabIndex(index);
        }
    }
    const buttons = [
        <div key={`b-0`} className={`tab-round-button ${activeTabIndex === 0 ? "tab-round-button-selected" : ""}`}
             onClick={() => tabButtonClickHandler(0)}>
            <StarIcon/>
        </div>
    ];
    const recordButtons = data?.records.map((r, i) => <div key={`b-${i + 1}`}
                                                           className={`tab-round-button ${activeTabIndex === i + 1 ? "tab-round-button-selected" : ""}`}
                                                           onClick={() => tabButtonClickHandler(i + 1)}>{i + 1}</div>);
    if (recordButtons && recordButtons.length > 1) {
        buttons.push(...recordButtons);
    }
    return (
        <div>
            <Breadcrumbs
                data={new Map([[token, record ? formatDateTime(record.dateTime) : null], [record?.patientId ?? "_", record?.patientName ?? null]])}/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchRecord}/>}
            {!isFetching && notFound && <NotFound/>}
            {(isOk && record && data) &&
                <div className="spiro-data-container">
                    <PatientInfo record={record}/>
                    {buttons &&
                        <div className="d-flex justify-content-center mt-4 mb-2">
                            {buttons}
                        </div>
                    }
                    <SpiroViewer spiroData={data} activeRecordIndex={activeTabIndex} recordId={record.id}
                                 studyId={record.spiroStudy.spiroId} isSupport={false}/>
                </div>
            }
            {(isOk && record && data) &&
                <SpiroDetailsTable record={record} data={data} isSupport={false}/>
            }
            {(isOk && record) &&
                <Conclusion initialValue={initialConclusion ?? ""} cachedValue={initialConclusion ?? ""}/>}
        </div>
    );
}
