import {UserAccessData} from "../../../models/UserAccessData";
import React from "react";
import {getUserPhotoUrl} from "../../../api/ApiHelper";
import './UserAccess.css';
import {ShareType} from "../../../models/ShareType";
import {useTranslation} from "react-i18next";
import {Code, Link} from "@mui/icons-material";
import {Avatar} from "@mui/material";

interface UserAccessProps {
    data: UserAccessData;
    showType: boolean;
    onClick: (id: string) => void;
}

const UserAccess: React.FC<UserAccessProps> = ({data, showType, onClick}: UserAccessProps) => {
    const {t} = useTranslation();
    const photoUrl = getUserPhotoUrl(data.photoUrl);
    const getTypeIcon = () => {
        switch (data.type){
            case ShareType.Link:
                return <div className={"type-icon"}><Code/><span>{t("share_link")}</span></div>;
            case ShareType.Association:
                return <div className={"type-icon"}><Link/><span>{t("share_association")}</span></div>;
            default:
                return <div/>
        }
    }
    return (
        <tr onClick={() => onClick(data.id)}>
            <td align="center" className="d-flex justify-content-center align-items-center"><Avatar
                className="user-avatar-img mx-2" alt={data.name} src={photoUrl}/>{data.name}</td>
            <td align="center">{data.email}</td>
            {showType &&
            <td align="center">{getTypeIcon()}</td>
            }
        </tr>
    );
}

export default UserAccess;