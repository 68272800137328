import {EcgInterpretationReport} from "../models/EcgInterpretationReport";
import {EcgInterpretationLocalization} from "../locale/EcgInterpretationData";

export function RenderEcgInterpretationReport(report : EcgInterpretationReport, language : string) : EcgInterpretationReport{
    if (language !== "en" && language !== "ru" && language !== "ua"){
        language = "en";
    }
    const newReport = {
        SolverVersion : report.SolverVersion,
        Timestamp : report.Timestamp,
        Interpretations : []
    } as EcgInterpretationReport;
    for (const group of  report.Interpretations){
        let groupText = group.Group;
        let interpretationText = group.Interpretation;
        if (groupText && interpretationText) {
            for (const interpretationTextKey in EcgInterpretationLocalization) {
                groupText = groupText.replaceAll(interpretationTextKey, EcgInterpretationLocalization[interpretationTextKey][language]);
                interpretationText = interpretationText.replaceAll(interpretationTextKey, EcgInterpretationLocalization[interpretationTextKey][language]);
            }
            newReport.Interpretations.push({Group: groupText, Interpretation: interpretationText});
        }
    }
    return newReport;
}