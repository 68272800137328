import React from "react";
import {useNavigate} from "react-router-dom";
import {RECORDS_ROUTE, USERS_ROUTE} from "../../../routes";
import {DATA_LIST_PAGE_SIZE} from "../../../settings";
import {RecordsList} from "../../Widgets/RecordsList/RecordsList";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {AccessType} from "../../../models/AccessType";
import {StudyType} from "../../Widgets/StudyIcon/StudyIcon";

function Records() {
    const history = useNavigate();
    const recordClickHandler = (id: string) => {
        history(`${RECORDS_ROUTE}/${id}`);
    };
    const studyClickHandler = (id: string, type: StudyType) => {
        switch (type) {
            case StudyType.State:
                recordClickHandler(id);
                break;
            case StudyType.Ecg:
                history(`${RECORDS_ROUTE}/${id}/ecg`)
                break;
            case StudyType.Stethoscope:
                history(`${RECORDS_ROUTE}/${id}/stethoscope`)
                break;
            case StudyType.Spiro:
                history(`${RECORDS_ROUTE}/${id}/spiro`)
                break;
            case StudyType.LtEcg:
                recordClickHandler(id);
                break;
        }
    };
    const userClickHandler = (id: string) => {
        history(`${USERS_ROUTE}/${id}`);
    };
    return (
        <div>
            <Breadcrumbs/>
            <RecordsList tag="list_records" pageSize={DATA_LIST_PAGE_SIZE} userId={null} accessType={AccessType.All} patientId={null}
                         recordClickHandler={recordClickHandler} studyClickHandler={studyClickHandler} userClickHandler={userClickHandler}/>
        </div>
    );
}

export default Records;