import moment from "moment";
import {Age} from "../models/Age";
import {Gender} from "../models/Gender";
import {HeightUnits} from "../models/HeightUnits";
import {Role} from "../models/Role";
import {WeightUnits} from "../models/WeightUnits";
import numeral from "numeral";
import {TFunction} from "i18next";

export function formatPatientName(t: TFunction<"translation">, isAnonymous: boolean, name: string): string {
    return isAnonymous ? t("anonymous") : name === '' ? t("unknown") : name;
}

export function formatGenderShort(t: TFunction<"translation">, gender: Gender): string {
    return gender === Gender.Male ? t("gender_male_short") : gender === Gender.Female ? t("gender_female_short") : t("gender_unspecified_short");
}

export function formatGenderFull(t: TFunction<"translation">, gender: Gender): string {
    return gender === Gender.Male ? t("gender_male_full") : gender === Gender.Female ? t("gender_female_full") : t("gender_unspecififed_full");
}

const AGE_BORDER_VALUE = 5;

export function formatAgeShort(age: Age): string {
    if (age.years > AGE_BORDER_VALUE) {
        return `${age.years}`;
    } else {
        return `${age.years}.${age.months}`;
    }
}

export function formatAgeFull(t: TFunction<"translation">, age: Age): string {
    if (age.years > AGE_BORDER_VALUE || age.months === 0) {
        return t("year_format", {count: age.years});
    } else {
        return `${t("year_format", {count: age.years})} ${t("month_format", {count: age.months})}`;
    }
}

export function formatBirthdate(date: Date): string {
    const birthdate = moment(date);
    return birthdate.format("DD.MM.YYYY");
}

export function formatDateTime(date: Date): string {
    const dateTime = moment(date).parseZone();
    return dateTime.format("DD.MM.YYYY\u00A0HH:mm ([GMT\u00A0]Z[)]")
}

export function formatTimestamp(timestamp: number) {
    const date = new Date(timestamp);
    const dateTime = moment(date);
    return dateTime.format("DD.MM.YYYY HH:mm:ss")
}

export function formatUtcTimestamp(date: Date) {
    const dateTime = moment.utc(date);
    return dateTime.local().format("DD.MM.YYYY HH:mm:ss")
}

export function formatDurationMs(ms: number): string {
    const minutes = Math.floor(ms / (60 * 1000));
    const seconds = Math.floor((ms % (60 * 1000)) / 1000);
    const milliseconds = ms % 1000;
    return `${numeral(minutes).format("00")}:${numeral(seconds).format("00")}.${numeral(milliseconds).format("000")}`;
}

export function formatDurationMsLong(ms: number): string {
    const s = ms / 1000;
    const hours = Math.floor(s / 3600);
    const minutes = Math.floor((s % 3600) / 60);
    const seconds = Math.floor(s % 60);
    return `${numeral(hours).format("00")}:${numeral(minutes).format("00")}:${numeral(seconds).format("00")}`;
}

export function formatLtEcgTime(ms: number): string {
    const date = new Date(ms);
    const dateTime = moment(date);
    return dateTime.format("HH:mm:ss")
}

export function parseZonedDateTime(timestamp: string): number {
    const dateTime = moment(timestamp);
    return dateTime.isValid() ? dateTime.valueOf() : 0;
}

export function formatDurationMsText(t: TFunction<"translation">, ms: number): string {
    const s = ms / 1000;
    const hours = Math.floor(s / 3600);
    const minutes = Math.floor((s % 3600) / 60);
    const seconds = Math.floor(s % 60);
    return t("duration_long_format", {hours: hours, minutes: minutes, seconds: seconds});
}

export function formatConfirmationState(t: TFunction<"translation">, state: boolean) {
    return state ? t("confirmed") : t("not_confirmed");
}

export function formatOnOffState(t: TFunction<"translation">, state: boolean) {
    return state ? t("on") : t("off");
}

export function getWeightUnitsName(t: TFunction<"translation">, units: WeightUnits) {
    switch (units) {
        case WeightUnits.Grams:
            return t("weight_units_g");
        case WeightUnits.Kilograms:
            return t("weight_units_kg");
        case WeightUnits.Pounds:
            return t("weight_units_lb");
        default:
            return "";
    }
}

export function formatWeight(t: TFunction<"translation">, weight: number, units: WeightUnits): string {
    return `${weight} ${getWeightUnitsName(t, units)}`;
}

export function getHeightUnitsName(t: TFunction<"translation">, units: HeightUnits) {
    switch (units) {
        case HeightUnits.Centimeters:
            return t("height_units_cm");
        case HeightUnits.Feet:
            return t("height_units_ft");
        case HeightUnits.Inches:
            return t("height_units_in");
        default:
            return "";
    }
}

export function formatHeight(t: TFunction<"translation">, height: number, units: HeightUnits): string {
    return `${height} ${getHeightUnitsName(t, units)}`;
}

export function formatRole(t: TFunction<"translation">, role: Role): string {
    switch (role) {
        case Role.Doctor:
            return t("role_doctor");
        case Role.Support:
            return t("role_support");
        case Role.Admin:
            return t("role_admin");
        default:
            return t("role_unknown");
    }
}

export function formatAccountState(t: TFunction<"translation">, isActive: boolean): string {
    return isActive ? t("status_active") : t("status_blocked");
}