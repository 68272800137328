export const EcgInterpretationLocalization : {[id: string] : {[lang: string] : string}} = {
	"[C0001]" : { "en" : "Noisy signal" , "ru" : "Шумный сигнал" , "ua" : "Шумний сигнал"  },
	"[C0002]" : { "en" : "Possible noisy signal" , "ru" : "Возможный шумный сигнал" , "ua" : "Можливий шумний сигнал"  },
	"[C0003]" : { "en" : "Negative" , "ru" : "Отрицательный" , "ua" : "Негативний" },
	"[C0004]" : { "en" : "Skipped" , "ru" : "Пропущено" , "ua" : "Пропущено" },
	"[S0001]" : { "en" : "Arm lead reversal and dextrocardia" ,  "ru" : "Перепутанные электроды на руках и декстрокардия" , "ua" : "Переплутані електроди на руках та декстрокардія" },
	"[S0002]" : { "en" : "Arm leads reversed" ,  "ru" : "Перепутанные электроды на руках" , "ua" : "Переплутані електроди на руках та декстрокардія" },
	"[S0003]" : { "en" : "Inverted P & QRS in lead I" ,  "ru" : "Перевернутые P и QRS в отведении I" , "ua" : "Перевернуті P і QRS у відведенні I" },
	"[S0004]" : { "en" : "Dextrocardia" ,  "ru" : "Декстрокардия" , "ua" : "Декстрокардія" },
	"[S0005]" : { "en" : "Inverted P & QRS in V6" ,  "ru" : "Перевернутые P и QRS в V6" , "ua" : "Перевернуті P і QRS в V6" },
	"[S0006]" : { "en" : "Wolff-Parkinson-White" ,  "ru" : "Синдром Вольф-Паркинсон-Уайт" , "ua" : "Синдром Вольф-Паркінсон-Уайт" },
	"[S0007]" : { "en" : "Atypical Wolff-Parkinson-White pattern" ,  "ru" : "Атипичный паттерн Вольф-Паркинсон-Уайт" , "ua" : "Атиповий патерн Вольф-Паркінсон-Уайт" },
	"[S0008]" : { "en" : "Type A Wolff-Parkinson-White pattern" ,  "ru" : "Тип А паттерн Вольф-Паркинсон-Уайт" , "ua" : "Тип А патерн Вольф-Паркінсон-Уайт" },
	"[S0009]" : { "en" : "Type B Wolff-Parkinson-White pattern" ,  "ru" : "Тип B патерн Вольф-Паркінсон-Уайт" , "ua" : "Тип B патерн Вольф-Паркінсон-Уайт" },
	"[S0010]" : { "en" : "Atrial enlargement" ,  "ru" : "Гипертрофия предсердий" , "ua" : "Гіпертрофія передсердь" },
	"[S0011]" : { "en" : "Possible right atrial enlargement" ,  "ru" : "Возможно увеличение правого предсердия" , "ua" : "Можливе збільшення правого передсердя" },
	"[S0012]" : { "en" : "0.25 mV P wave" ,  "ru" : "Зубец Р 0,25 мВ" , "ua" : "Зубець Р 0,25 мВ" },
	"[S0013]" : { "en" : "Right atrial enlargement" ,  "ru" : "Увеличение правого предсердия" , "ua" : "Збільшення правого передсердя" },
	"[S0014]" : { "en" : "0.3 mV P wave" ,  "ru" : "Зубец Р 0,3 мВ" , "ua" : "Зубець Р 0,3 мВ" },
	"[S0015]" : { "en" : "Possible left atrial enlargement" ,  "ru" : "Возможно увеличение левого предсердия" , "ua" : "Можливе збільшення лівого передсердя" },
	"[S0016]" : { "en" : "-0.1 mV P wave in V1/V2" ,  "ru" : "Зубец P -0,1 мВ в V1/V2" , "ua" : "Зубець P -0,1 мВ V1/V2" },
	"[S0017]" : { "en" : "Left atrial enlargement" ,  "ru" : "Увеличение левого предсердия" , "ua" : "Збільшення лівого передсердя" },
	"[S0018]" : { "en" : "-0.15 mV P wave in V1/V2" ,  "ru" : "Зубец P -0,15 мВ в V1/V2" , "ua" : "Зубець P -0,15 мВ V1/V2" },
	"[S0019]" : { "en" : "Axis deviation" ,  "ru" : "Отклонение оси" , "ua" : "Відхилення осі" },
	"[S0020]" : { "en" : "Moderate left axis deviation" ,  "ru" : "Умеренное отклонение оси влево" , "ua" : "Помірне відхилення осі вліво" },
	"[S0021]" : { "en" : "QRS axis < -20" ,  "ru" : "Ось QRS < -20" , "ua" : "Вісь QRS < -20" },
	"[S0022]" : { "en" : "Abnormal left axis deviation" ,  "ru" : "Аномальное отклонение оси влево" , "ua" : "Аномальне відхилення осі вліво" },
	"[S0023]" : { "en" : "QRS axis < -30" ,  "ru" : "Ось QRS < -30" , "ua" : "Вісь QRS < -30" },
	"[S0024]" : { "en" : "Moderate right axis deviation" ,  "ru" : "Умеренное отклонение оси вправо" , "ua" : "Помірне відхилення осі праворуч" },
	"[S0025]" : { "en" : "QRS axis > 90" ,  "ru" : "Ось QRS > 90" , "ua" : "Вісь QRS > 90" },
	"[S0026]" : { "en" : "Abnormal right axis deviation" ,  "ru" : "Аномальное отклонение оси вправо" , "ua" : "Аномальне відхилення осі праворуч" },
	"[S0027]" : { "en" : "QRS axis > 100" ,  "ru" : "Ось QRS> 100" , "ua" : "Вісь QRS> 100" },
	"[S0028]" : { "en" : "Indeterminate axis" ,  "ru" : "Неопределенная ось" , "ua" : "Невизначена вісь" },
	"[S0029]" : { "en" : "Low voltage" ,  "ru" : "Низкий вольтаж" , "ua" : "Низький вольтаж" },
	"[S0030]" : { "en" : "Low QRS voltage in limb leads" ,  "ru" : "Низкий вольтаж QRS в отведениях от конечностей" , "ua" : "Низький вольтаж QRS у відведеннях від кінцівок" },
	"[S0031]" : { "en" : "QRS deflection < 0.5 mV in limb leads" ,  "ru" : "Амплитуда QRS < 0,5 мВ в отведениях от конечностей" , "ua" : "Амплітуда QRS < 0,5 мВ у відведеннях від кінцівок" },
	"[S0032]" : { "en" : "Low QRS voltage in chest leads" ,  "ru" : "Низкий вольтаж комплекса QRS в грудных отведениях" , "ua" : "Низький вольтаж комплексу QRS у грудних відведеннях" },
	"[S0033]" : { "en" : "QRS deflection < 1.0 mV in chest leads" ,  "ru" : "Амплитуда QRS < 1,0 мВ в грудных отведениях" , "ua" : "Амплітуда QRS < 1,0 мВ у грудних відведеннях" },
	"[S0034]" : { "en" : "Low QRS voltage" ,  "ru" : "Низкий вольтаж QRS" , "ua" : "Низький вольтаж QRS" },
	"[S0035]" : { "en" : "QRS deflection < 0.5/1.0 mV in limb/chest leads" ,  "ru" : "Амплитуда QRS < 0,5/1,0 мВ в отведениях от конечностей/грудной клетки" , "ua" : "Амплітуда QRS < 0,5/1,0 мВ у відведеннях від кінцівок/грудної клітини" },
	"[S0036]" : { "en" : "S1-S2-S3 pattern" ,  "ru" : "S1-S2-S3 паттерн" , "ua" : "S1-S2-S3 патерн" },
	"[S0037]" : { "en" : "S1-S2-S3 pattern, consistent with pulmonary disease, RVH, or normal variant" ,  "ru" : "S1-S2-S3 паттерн соответствует лёгочному заболеванию, ГПЖ или нормальному варианту" , "ua" : "S1-S2-S3 патерн відповідає легеневому захворюванню, ГПЗ або нормальному варіанту" },
	"[S0038]" : { "en" : "Pulmonary disease" ,  "ru" : "Вероятно лёгочное заболевание" , "ua" : "Ймовірно легеневе захворювання" },
	"[S0039]" : { "en" : "Consistent with pulmonary disease" ,  "ru" : "Соответствует легочному заболеванию" , "ua" : "Відповідає легеневому захворюванню" },
	"[S0040]" : { "en" : "Right bundle conduction" ,  "ru" : "Проведение по правой ножке пучка Гиса" , "ua" : "Проведення по правій ніжці пучка Гіса" },
	"[S0041]" : { "en" : "RsR' (QR) in V1/V2 consistent with right ventricular conduction delay" ,  "ru" : "RsR' (QR) в V1/V2 соответствует замедлению проводимости по правой ножке пучка Гиса" , "ua" : "RsR' (QR) у V1/V2 відповідає уповільненню провідності по правій ніжці пучка Гіса" },
	"[S0042]" : { "en" : "Incomplete right bundle branch block" ,  "ru" : "Неполная блокада правой ножки пучка Гиса" , "ua" : "Неповна блокада правої ніжки пучка Гіса" },
	"[S0043]" : { "en" : "90+ ms QRS duration, terminal R in V1/V2, 40+ ms S in I/aVL/V4/V5/V6" ,  "ru" : "Продолжительность комплекса QRS 90+ мс, окончание комплекса QRS в виде зубца R в отведениях V1/V2, ширина зубца S 40+ мс в I/aVL/V4/V5/V6" , "ua" : "Тривалість комплексу QRS 90+ мс, закінчення комплексу QRS у вигляді зубця R у відведеннях V1/V2, ширина зубця S 40+ мс I/aVL/V4/V5/V6" },
	"[S0044]" : { "en" : "Right bundle branch block" ,  "ru" : "Блокада правой ножки пучка Гиса" , "ua" : "Блокада правої ніжки пучка Гіса" },
	"[S0045]" : { "en" : "120+ ms QRS duration, upright V1, 40+ ms S in I/aVL/V4/V5/V6" ,  "ru" : "Продолжительность комплекса QRS 120+ мс, комплекс QRS(V1) направлен вверх, ширина зубца S 40+ мс в I/aVL/V4/V5/V6" , "ua" : "Тривалість комплексу QRS 120+ мс, комплекс QRS(V1) спрямований вгору, ширина зубця S 40+ мс I/aVL/V4/V5/V6" },
	"[S0046]" : { "en" : "Right bundle branch block plus possible right ventricular hypertrophy" ,  "ru" : "Блокада правой ножки пучка Гиса плюс возможная гипертрофия правого желудочка" , "ua" : "Блокада правої ніжки пучка Гіса плюс можлива гіпертрофія правого шлуночка" },
	"[S0047]" : { "en" : "RBBB, 1.5 mV R in V1, RAD" ,  "ru" : "БПНПГ, амплитуда зубца R 1,5 мВ в V1, отклонение электрической оси вправо" , "ua" : "БПНПГ, амплітуда зубця R 1,5 мВ V1, відхилення електричної осі вправо" },
	"[S0048]" : { "en" : "Left bundle conduction" ,  "ru" : "Проводимость по левой ножке пучка Гиса" , "ua" : "Провідність по лівій ніжці пучка Гіса" },
	"[S0049]" : { "en" : "Incomplete left bundle branch block" ,  "ru" : "Неполная блокада левой ножки пучка Гиса" , "ua" : "Неповна блокада лівої ніжки пучка Гіса" },
	"[S0050]" : { "en" : "105+ ms QRS duration, 80+ ms Q/S in V1/V2 no Q and 60+ ms R in I/aVL/V5/V6" ,  "ru" : "Продолжительность QRS 105+ мС, продолжительность QRS в форме Q/S 80+ мc в V1/V2, отсуствие зубца Q и ширина зубца R 60+ мc в I/aVL/V5/V" , "ua" : "Тривалість QRS 105+ мc, тривалість QRS у формі Q/S 80+ мc у V1/V2, відсутність зубця Q та ширина зубця R 60+ мc у I/aVL/V5/V" },
	"[S0051]" : { "en" : "Left anterior fascicular block" ,  "ru" : "Блокада передней ветви левой ножки пучка Гиса" , "ua" : "Блокада передньої гілки лівої ніжки пучка Гіса" },
	"[S0052]" : { "en" : "QRS axis ≤ -45, QR in I, RS in II" ,  "ru" : "Ось QRS ≤ -45, QR в I, RS во II" , "ua" : "Вісь QRS ≤ -45, QR в I, RS у II" },
	"[S0053]" : { "en" : "Left posterior fascicular block" ,  "ru" : "Блокада задней ветви левой ножки пучка Гиса" , "ua" : "Блокада задньої гілки лівої ніжки пучка Гіса" },
	"[S0054]" : { "en" : "QRS axis > 109, inferior Q" ,  "ru" : "Ось QRS > 109, зубец Q в нижних отведениях" , "ua" : "Вісь QRS > 109, зубець Q у нижніх відведеннях" },
	"[S0055]" : { "en" : "Left bundle branch block" ,  "ru" : "Блокада левой ножки пучка Гиса" , "ua" : "Блокада лівої ніжки пучка Гіса" },
	"[S0056]" : { "en" : "120+ ms QRS duration, 80+ ms Q/S in V1/V2, 85+ ms R in I/aVL/V6" ,  "ru" : "Продолжительность QRS 120+ мc, ширина QRS в форме Q/S 80+ мc в V1/V2, ширина зубца R 85+ мc в I/aVL/V6" , "ua" : "Тривалість QRS 120+ мc, ширина QRS у формі Q/S 80+ мc у V1/V2, ширина зубця R 85+ мc у I/aVL/V6" },
	"[S0057]" : { "en" : "Non specific conduction abnormality" ,  "ru" : "Неспецифические нарушения (внутрижелудочковой) проводимости" , "ua" : "Неспецифічні порушення (внутрішньошлуночкової) провідності" },
	"[S0058]" : { "en" : "Non specific intraventricular conduction delay" ,  "ru" : "Неспецифические нарушения внутрижелудочковой проводимости" , "ua" : "Неспецифічні порушення внутрішньошлуночкової провідності" },
	"[S0059]" : { "en" : "110+ ms QRS duration" ,  "ru" : "Продолжительность комплекса QRS 110+ мc" , "ua" : "Тривалість комплексу QRS 110+ мc" },
	"[S0060]" : { "en" : "Non specific intraventricular conduction block" ,  "ru" : "Неспецифическая блокада внутрижелудочковой проводимости" , "ua" : "Неспецифічна блокада внутрішньошлуночкової провідності" },
	"[S0061]" : { "en" : "130+ ms QRS duration" ,  "ru" : "Продолжительность комплекса QRS  130+ мc" , "ua" : "Тривалість комплексу QRS 130+ мc" },
	"[S0062]" : { "en" : "Right ventricular hypertrophy" ,  "ru" : "Гипертрофия правого желудочка" , "ua" : "Гіпертрофія правого шлуночка" },
	"[S0063]" : { "en" : "Possible right ventricular hypertrophy" ,  "ru" : "Возможна гипертрофия правого желудочка" , "ua" : "Можлива гіпертрофія правого шлуночка" },
	"[S0064]" : { "en" : "Some/all of: prominent R in V1, late transition, RAD, RAE, SSS" ,  "ru" : "Некоторые или все из перечисленных признаков: выраженный зубец R в V1, поздний переход, отклонение электрической оси вправо, увеличение правого предсердия, синдром слабости синусового узла" , "ua" : "Деякі або всі з цих ознак: виражений зубець R в V1, пізній перехід, відхилення електричної осі вправо, збільшення правого передсердя, синдром слабкості синусового вузла" },
	"[S0065]" : { "en" : "Right ventricular hypertrophy with repolarization abnormality" ,  "ru" : "Гипертрофия правого желудочка с нарушением реполяризации" , "ua" : "Гіпертрофія правого шлуночка з порушенням реполяризації" },
	"[S0066]" : { "en" : "Some/all of: prominent R in V1, late transition, RAD, RAE, SSS, right precordial ST depression" ,  "ru" : "Некоторые или все из перечисленных признаков: выраженный зубец R в V1, поздний переход, отклонение электрической оси вправо, увеличение правого предсердия, синдром слабости синусового узла, депрессия сегмента ST в правых прекардиальных отведениях" , "ua" : "Деякі або всі з перелічених ознак: виражений зубець R в V1, пізній перехід, відхилення електричної осі вправо, збільшення правого передсердя, синдром слабкості синусового вузла, депресія сегмента ST у правих прекардіальних відведеннях" },
	"[S0067]" : { "en" : "Minimal voltage criteria for left ventricular hypertrophy, may be normal variant" ,  "ru" : "Минимальные амплитудные критерии гипертрофии левого желудочка, возможно нормальный вариант" , "ua" : "Мінімальні амплітудні критерії гіпертрофії лівого шлуночка, можливо нормальний варіант" },
	"[S0068]" : { "en" : "Meets criteria in one of: R(aVL), S(V1), R(V5), R(V5/V6)+S(V1)" ,  "ru" : "Соответствует одному из критериев: R(aVL), S(V1). R(V5), R(V5//V6)+S(V1)" , "ua" : "Відповідає одному з критеріїв: R(aVL), S(V1). R(V5), R(V5//V6)+S(V1)" },
	"[S0069]" : { "en" : "Moderate voltage criteria for left ventricular hypertrophy, may be normal variant" ,  "ru" : "Умеренные амплитудные критерии гипертрофии левого желудочка, возможно нормальный вариант" , "ua" : "Помірні амплітудні критерії гіпертрофії лівого шлуночка, можливий нормальний варіант" },
	"[S0070]" : { "en" : "Voltage criteria for left ventricular hypertrophy are present" ,  "ru" : "Имеются амплитудные критерии гипертрофии левого желудочка" , "ua" : "Є амплітудні критерії гіпертрофії лівого шлуночка." },
	"[S0071]" : { "en" : "Possible left ventricular hypertrophy" ,  "ru" : "Возможно гипертрофия левого желудочка" , "ua" : "Можлива гіпертрофія лівого шлуночка" },
	"[S0072]" : { "en" : "Voltage criteria plus LAE or QRS widening" ,  "ru" : "Амплитудные критери плюс признаки увеличения левого предсердия или расширение комплекса QRS" , "ua" : "Амплітудні критерії плюс ознаки збільшення лівого передсердя або розширення комплексу QRS" },
	"[S0073]" : { "en" : "Left ventricular hypertrophy with repolarization abnormality" ,  "ru" : "Гипертрофия левого желудочка с нарушением реполяризации" , "ua" : "Гіпертрофія лівого шлуночка з порушенням реполяризації" },
	"[S0073a]" : { "en" : "Left ventricular hypertrophy" ,  "ru" : "Гипертрофия левого желудочка" , "ua" : "Гіпертрофія лівого шлуночка" },
	"[S0074]" : { "en" : "Voltage criteria plus ST/T abnormality" ,  "ru" : "Амплитудные критерии плюс нарушение ST/T" , "ua" : "Амплітудні критерії плюс порушення ST/T" },
	"[S0075]" : { "en" : "Anterior infarct" ,  "ru" : "Передний инфаркт" , "ua" : "Передній інфаркт" },
	"[S0076]" : { "en" : "(possibly acute)" ,  "ru" : "(возможно острый)" , "ua" : "(можливо гострий)" },
	"[S0077]" : { "en" : "(probably recent)" ,  "ru" : "(возможно недавно перенесенный)" , "ua" : "(можливо нещодавно перенесений)" },
	"[S0078]" : { "en" : "(probably old)" ,  "ru" : "(вероятно старый)" , "ua" : "(ймовірно старий)" },
	"[S0079]" : { "en" : "(age undetermined)" ,  "ru" : "(давность не установлена)" , "ua" : "(давність не встановлено)" },
	"[S0080]" : { "en" : "Cannot rule out anterior infarct" ,  "ru" : "Нельзя исключить передний инфаркт" , "ua" : "Не можна виключити передній інфаркт" },
	"[S0081]" : { "en" : "30 ms Q wave in V3/V4 or R < 0.2mV in V4" ,  "ru" : "Ширина зубца Q 30 мс в V3/V4 или амплитуда зубца R <0,2 мВ в V4" , "ua" : "Ширина зубця Q 30 мс у V3/V4 або амплітуда зубця R <0,2 мВ у V4" },
	"[S0082]" : { "en" : "Possible anterior infarct" ,  "ru" : "Возможно передний инфаркт" , "ua" : "Можливо передній інфаркт" },
	"[S0083]" : { "en" : "35 ms Q wave in V3/V4" ,  "ru" : "Ширина зубца Q 30 мс в V3/V4 " , "ua" : "Ширина зубця Q 30 мс у V3/V4" },
	"[S0084]" : { "en" : "40+ ms Q wave and/or ST/T is abnormality in V3/V4" ,  "ru" : "Ширина зубца Q 40+ мс и/или нарушения ST/T в V3/V4" , "ua" : "Ширина зубця Q 40+ мс та/або порушення ST/T у V3/V4" },
	"[S0085]" : { "en" : "Septal infarct" ,  "ru" : "Перегородочный инфаркт" , "ua" : "Перегородковий інфаркт" },
	"[S0086]" : { "en" : "Cannot rule out septal infarct" ,  "ru" : "Нельзя исключить перегородочный инфаркт" , "ua" : "Не можна виключити перегородковий інфаркт" },
	"[S0087]" : { "en" : "30 ms Q wave in V1/V2" ,  "ru" : "Ширина зубца Q 30  мc в V1/V2" , "ua" : "Ширина зубця Q 30 мc у V1/V2" },
	"[S0088]" : { "en" : "Possible septal infarct" ,  "ru" : "Возможно перегородочный инфаркт" , "ua" : "Можливий перегородковий інфаркт" },
	"[S0089]" : { "en" : "35 ms Q wave in V1/V2" ,  "ru" : "Ширина зубца Q 35 мс в V1/V2" , "ua" : "Ширина зубця Q 35 мс у V1/V2" },
	"[S0090]" : { "en" : "40+ ms Q wave in V1/V2" ,  "ru" : "Ширина зубца Q 40+ мс в V1/V2" , "ua" : "Ширина зубця Q 40+ мс у V1/V2" },
	"[S0091]" : { "en" : "Anteroseptal infarct" ,  "ru" : "Переднеперегородочный инфаркт" , "ua" : "Передньоперегородковий інфаркт" },
	"[S0092]" : { "en" : "Cannot rule out anteroseptal infarct" ,  "ru" : "Нельзя исключить переднеперегородочный инфаркт" , "ua" : "Не можна виключити переднеперегородковий інфаркт" },
	"[S0093]" : { "en" : "30 ms Q wave in V1-V4" ,  "ru" : "Ширина зубца Q 30 мc в V1-V4" , "ua" : "Ширина зубця Q 30 мc у V1-V4" },
	"[S0094]" : { "en" : "Possible anteroseptal infarct" ,  "ru" : "Возможно переднеперегородочный инфаркт" , "ua" : "Можливо передньоперегородковий інфаркт" },
	"[S0095]" : { "en" : "35 ms Q wave in V1-V4" ,  "ru" : "Ширина зубца Q 35 мc в V1-V4" , "ua" : "Ширина зубця Q 35 мc у V1-V4" },
	"[S0096]" : { "en" : "40+ ms Q wave in V1-V4" ,  "ru" : "Ширина зубца Q 40+ мc в V1-V4" , "ua" : "Ширина зубця Q 40+ мc у V1-V4" },
	"[S0097]" : { "en" : "Lateral infarct" ,  "ru" : "Боковой инфаркт" , "ua" : "Бічний інфаркт" },
	"[S0098]" : { "en" : "Cannot rule out lateral infarct" ,  "ru" : "Нельзя исключить боковой инфаркт" , "ua" : "Не можна виключити бічний інфаркт" },
	"[S0099]" : { "en" : "30 ms Q wave in I/aVL/V5/V6" ,  "ru" : "Ширина зубца Q 30 мс в I/aVL/V5/V6" , "ua" : "Ширина зубця Q 30 мс I/aVL/V5/V6" },
	"[S0100]" : { "en" : "Possible lateral infarct" ,  "ru" : "Возможно боковой инфаркт" , "ua" : "Можливо бічний інфаркт" },
	"[S0101]" : { "en" : "35 ms Q wave in I/V5/V6" ,  "ru" : "Ширина зубца Q 35 мс в I/V5/V6" , "ua" : "Ширина зубця Q 35 мс у I/V5/V6" },
	"[S0102]" : { "en" : "40+ ms Q wave and/or ST/T abnormality in I/aVL/V5/V6" ,  "ru" : "Ширина зубца Q 40+ мс и/или нарушение ST/T в I/aVL/V5/V6" , "ua" : "Ширина зубця Q 40+ мс та/або порушення ST/T в I/aVL/V5/V6" },
	"[S0103]" : { "en" : "Anterolateral infarct" ,  "ru" : "Переднебоковой инфаркт" , "ua" : "Передньобоковий інфаркт" },
	"[S0104]" : { "en" : "Cannot rule out anterolateral infarct" ,  "ru" : "Нельзя исключить переднебоковой инфаркт" , "ua" : "Не можна виключити переднебоковий інфаркт" },
	"[S0105]" : { "en" : "30 ms Q wave in I/aVL/V3-V6" ,  "ru" : "Ширина зубца Q 30 мс в I/aVL/V3-V6" , "ua" : "Ширина зубця Q 30 мс I/aVL/V3-V6" },
	"[S0106]" : { "en" : "Possible anterolateral infarct" ,  "ru" : "Возможно переднебоковой инфаркт" , "ua" : "Можливо передньобічний інфаркт" },
	"[S0107]" : { "en" : "35 ms Q wave in I/aVL/V3-V6" ,  "ru" : "Ширина зубца Q 35 мс в I/aVL/V3-V6" , "ua" : "Ширина зубця Q 35 мс в I/aVL/V3-V6" },
	"[S0108]" : { "en" : "40+ ms Q wave in I/aVL/V3-V6" ,  "ru" : "Ширина зубца Q 40+ мс в I/aVL/V3-V6" , "ua" : "Ширина зубця Q 40+ мс I/aVL/V3-V6" },
	"[S0109]" : { "en" : "Inferior infarct" ,  "ru" : "Нижний инфаркт" , "ua" : "Нижній інфаркт" },
	"[S0110]" : { "en" : "Cannot rule out inferior infarct" ,  "ru" : "Нельзя исключить нижний инфаркт" , "ua" : "Не можна виключити нижній інфаркт" },
	"[S0111]" : { "en" : "30 ms Q wave in II/aVF" ,  "ru" : "Ширина зубца Q 30 мс в II/fVF" , "ua" : "Ширина зубця Q 30 мс у II/fVF" },
	"[S0112]" : { "en" : "Possible inferior infarct" ,  "ru" : "Возможно нижний инфаркт" , "ua" : "Можливо нижній інфаркт" },
	"[S0113]" : { "en" : "35 ms Q wave in II/aVF" ,  "ru" : "Ширина зубца Q 35 мс в II/avF" , "ua" : "Ширина зубця Q 35 мс у II/avF" },
	"[S0114]" : { "en" : "40+ ms Q wave and/or ST/T abnormality in II/aVF" ,  "ru" : "Ширина зубца Q 40+ мс и/или нарушения ST/T в II/avF" , "ua" : "Ширина зубця Q 40+ мс та/або порушення ST/T у II/avF" },
	"[S0115]" : { "en" : "with posterior extension prominent R Wave in V1/V2" ,  "ru" : "с распространением на задню стенку (выраженный зубец R в V1/V2)" , "ua" : "з поширенням на задню стінку (виражений зубець R в V1/V2)" },
	"[S0116]" : { "en" : "ST segment elevation" ,  "ru" : "Подъём сегмента ST" , "ua" : "Підйом сегмента ST" },
	"[S0117]" : { "en" : "Non specific ST elevation" ,  "ru" : "Неспецифический подъем сегмента ST" , "ua" : "Неспецифічний підйом сегмента ST" },
	"[S0118]" : { "en" : "0.05+ mV ST elevation" ,  "ru" : "Подъём сегмента ST амплитудой 0,05+ мВ" , "ua" : "Підйом сегмента ST амплітудою 0,05+ мВ" },
	"[S0119]" : { "en" : "Early repolarization" ,  "ru" : "Синдром ранней реполяризации" , "ua" : "Синдром ранньої реполяризації" },
	"[S0120]" : { "en" : "ST elevation, consistent with epicardial injury, pericarditis, or early repolarization" ,  "ru" : "Подъём сегмента ST, соответствует эпикардиальному повреждению, перикардиту или синдрому ранней реполяризации" , "ua" : "Підйом сегмента ST, відповідає епікардіальному пошкодженню, перикардиту або синдрому ранньої реполяризації." },
	"[S0121]" : { "en" : "ST elevation w/o normally leads inflected T wave" ,  "ru" : "Подъём сегмента ST без изменчивой в норме формы зубца T" , "ua" : "Підйом сегмента ST без мінливої у нормі форми зубця T" },
	"[S0122]" : { "en" : "ST elevation, probably early repolarization" ,  "ru" : "Подъём сегмента ST, вероятно, синдром преждевременной реполяризации" , "ua" : "Підйом сегмента ST, ймовірно, синдром передчасної реполяризації" },
	"[S0123]" : { "en" : "ST elevation with normally inflected T wave" ,  "ru" : "Подъём сегмента ST с нормальной изменчивостью формы зубца T" , "ua" : "Підйом сегмента ST із нормальною мінливістю форми зубця T" },
	"[S0124]" : { "en" : "Pericarditis" ,  "ru" : "Перикардит" , "ua" : "Перикардит" },
	"[S0125]" : { "en" : "Possible acute pericarditis" ,  "ru" : "Возможно острый перикардит" , "ua" : "Можливий гострий перикардит" },
	"[S0126]" : { "en" : "Marked ST elevation w/o normally inflected T wave" ,  "ru" : "Выраженный подъём сегмента ST без нормальной изменчивости зубца T" , "ua" : "Виражений підйом сегмента ST без нормальної мінливості зубця T" },
	"[S0127]" : { "en" : "Acute pericarditis" ,  "ru" : "Острый перикардит" , "ua" : "Гострий перикардит" },
	"[S0128]" : { "en" : "Anterior and septal epicardial Injury" ,  "ru" : "Переднее и перегородочное эпикардиаьное повреждение" , "ua" : "Переднє та перегородкове епікардіальне пошкодження" },
	"[S0129]" : { "en" : "Possible septal epicardial injury" ,  "ru" : "Возможно перегородочное эпикардиальное повреждение" , "ua" : "Можливе перегородкове епікардіальне ушкодження" },
	"[S0130]" : { "en" : "Marked ST elevation w/o normally inflected T wave in V1/V2" ,  "ru" : "Выраженный подъём сегмента ST без нормальной изменчивости формы зубца T в V1/V2" , "ua" : "Виражений підйом сегмента ST без нормальної мінливості форми зубця T в V1/V2" },
	"[S0131]" : { "en" : "Septal epicardial injury" ,  "ru" : "Перегородочное эпикардиальное повреждение" , "ua" : "Перегородкове епікардіальне ушкодження" },
	"[S0132]" : { "en" : "Possible anterior epicardial injury" ,  "ru" : "Возможно переднее эпикардиальное повреждение" , "ua" : "Можливе переднє епікардіальне ушкодження" },
	"[S0133]" : { "en" : "Marked ST elevation w/o normally inflected T wave in V2-V5" ,  "ru" : "Выраженный подъём сегмента ST без нормальной изменчивости зубца T в V2-V5" , "ua" : "Виражений підйом сегмента ST без нормальної мінливості зубця T в V2-V5" },
	"[S0134]" : { "en" : "Anterior epicardial injury" ,  "ru" : "Переднее эпикардиальное повреждение" , "ua" : "Переднє епікардіальне ушкодження" },
	"[S0135]" : { "en" : "Possible anteroseptal epicardial injury" ,  "ru" : "Возможно переднеперегородочное эпикардиальное повреждение" , "ua" : "Можливо передньоперегородкове епікардіальне ушкодження" },
	"[S0136]" : { "en" : "Marked ST elevation w/o normally inflected T wave in V1-V4" ,  "ru" : "Выраженный подъём сегмента ST без нормальной изменчивости зубца T в V1-V4" , "ua" : "Виражений підйом сегмента ST без нормальної мінливості зубця T в V1-V4" },
	"[S0137]" : { "en" : "Anteroseptal epicardial injury" ,  "ru" : "Переднеперегородочное эпикардиальное повреждение" , "ua" : "Передньоперегородкове епікардіальне ушкодження" },
	"[S0138]" : { "en" : "Lateral epicardial injury" ,  "ru" : "Эпикардиальное повреждение боковой стенки" , "ua" : "Епікардіальне пошкодження бічної стінки" },
	"[S0139]" : { "en" : "Possible lateral epicardial injury" ,  "ru" : "Возможно эпикардиальное повреждение боковой стенки" , "ua" : "Можливе епікардіальне пошкодження бічної стінки" },
	"[S0140]" : { "en" : "Marked ST elevation w/o normally inflected T wave in I/aVL/V5/V6" ,  "ru" : "Выраженный подъём сегмента ST без без нормальной изменчивости зубца T в I/aVL/V5/V6" , "ua" : "Виражений підйом сегмента ST без нормальної мінливості зубця T в I/aVL/V5/V6" },
	"[S0141]" : { "en" : "Possible anterolateral epicardial injury" ,  "ru" : "Возможно эпикардиальное повреждение переднебоковой стенки" , "ua" : "Можливе епікардіальне пошкодження передньобічної стінки" },
	"[S0142]" : { "en" : "Marked ST elevation w/o normally inflected T wave in V3-V6" ,  "ru" : "Выраженный подъём сегмента ST без без нормальной изменчивости зубца T в V3-V6" , "ua" : "Виражений підйом сегмента ST без нормальної мінливості зубця T в V3-V6" },
	"[S0143]" : { "en" : "Anterolateral epicardial injury" ,  "ru" : "Эпикардиальное повреждение переднебоковой стенки" , "ua" : "Епікардіальне пошкодження передньобічної стінки" },
	"[S0144]" : { "en" : "Inferior epicardial injury" ,  "ru" : "Эпикардиальное повреждение нижней стенки" , "ua" : "Епікардіальне пошкодження нижньої стінки" },
	"[S0145]" : { "en" : "Possible inferior epicardial injury" ,  "ru" : "Возможно эпикардиальное повреждение нижней стенки" , "ua" : "Можливе епікардіальне пошкодження нижньої стінки" },
	"[S0146]" : { "en" : "Marked ST elevation w/o normally inflected T wave in II/aVF" ,  "ru" : "Выраженнй подъём сегмента ST без нормальной изменчивости зубца T в II/aVF" , "ua" : "Виражений підйом сегмента ST без нормальної мінливості зубця T у II/aVF" },
	"[S0147]" : { "en" : "ST depression" ,  "ru" : "Депрессия сегмента ST" , "ua" : "Депресія сегмента ST" },
	"[S0148]" : { "en" : "Junctional depression, probably normal" ,  "ru" : "Узловая депрессия, возможно норма" , "ua" : "Вузлова депресія, можлива норма" },
	"[S0149]" : { "en" : "0.1+ mV junctional ST depression" ,  "ru" : "0,1+ мВ узловая депрессия ST" , "ua" : "0,1+ мВ вузлова депресія ST" },
	"[S0150]" : { "en" : "Abnormal junctional depression" ,  "ru" : "Аномальная узловая депрессия" , "ua" : "Аномальна вузлова депресія" },
	"[S0151]" : { "en" : "Junctional depression with weak upslope" ,  "ru" : "Небольшой подъём сегмента ST в точке соединения" , "ua" : "Невеликий підйом сегмента ST у точці з'єднання" },
	"[S0152]" : { "en" : "ST depression, possible digitalis effect" ,  "ru" : "Депрессия сегмента ST, возможно под влиянием дигиталиса" , "ua" : "Депресія сегмента ST, можливо під впливом дигіталісу" },
	"[S0153]" : { "en" : "Downsloping or coved ST depression" ,  "ru" : "Восходящая или выпуклая депрессия сегмента ST" , "ua" : "Висхідна або опукла депресія сегмента ST" },
	"[S0154]" : { "en" : "Minimal ST depression" ,  "ru" : "Минимальная депрессия сегмента ST" , "ua" : "Мінімальна депресія сегмента ST" },
	"[S0155]" : { "en" : "0.025+ mV ST depression" ,  "ru" : "Подъём сегмента ST на 0,025+ мВ" , "ua" : "Підйом сегмента ST на 0,025+ мВ" },
	"[S0156]" : { "en" : "Moderate ST depression" ,  "ru" : "Умеренная депрессия сегмента ST" , "ua" : "Помірна депресія сегмента ST" },
	"[S0157]" : { "en" : "0.05+ mV ST depression" ,  "ru" : "Подъём сегмента ST на 0,05+ мВ" , "ua" : "Підйом сегмента ST на 0,05+ мВ" },
	"[S0158]" : { "en" : "Marked ST depression, possible subendocardial injury" ,  "ru" : "Выраженная депрессия ST, возможно субэндокардиальное повреждение" , "ua" : "Виражена депресія ST, можливе субендокардіальне ушкодження" },
	"[S0159]" : { "en" : "0.1+ mV ST depression" ,  "ru" : "Подъём сегмента ST на 0,1+ мВ" , "ua" : "Підйом сегмента ST на 0,1+ мВ" },
	"[S0160]" : { "en" : "Marked ST depression, consistent with subendocardial injury" ,  "ru" : "Выраженная депрессия сегмента ST, соответствует субэндокардиальному повреждению" , "ua" : "Виражена депресія сегмента ST відповідає субендокардіальному пошкодженню" },
	"[S0161]" : { "en" : "0.2+ mV ST depression" ,  "ru" : "Подъём сегмента ST на 0,2+ мВ" , "ua" : "Підйом сегмента ST на 0,2+ мВ" },
	"[S0162]" : { "en" : "(probably digitalis effect)" ,  "ru" : "(вероятно, эффект дигиталиса)" , "ua" : "(ймовірно, ефект дигіталісу)" },
	"[S0163]" : { "en" : "or digitalis effect" ,  "ru" : "или эффект дигиталиса" , "ua" : "або ефект дигіталісу" },
	"[S0164]" : { "en" : "T wave abnormality, ischemia" ,  "ru" : "Аномалия зубца T, ишемия" , "ua" : "Аномалія зубця T, ішемія" },
	"[S0165]" : { "en" : "T wave abnormality, possible anterior ischemia" ,  "ru" : "Аномалия зубца T, возможно передняя ишемия" , "ua" : "Аномалія зубця T, можливо передня ішемія" },
	"[S0166]" : { "en" : "-0.1+ mV T wave in V3/V4" ,  "ru" : "Зубец T -0,1+ мВ в V3/V4" , "ua" : "Зубець T -0,1+ мВ V3/V4" },
	"[S0167]" : { "en" : "T wave abnormality, consistent with anterior ischemia" ,  "ru" : "Аномалия зубца T, соответствующая передней ишемии" , "ua" : "Аномалія зубця T, що відповідає передній ішемії" },
	"[S0168]" : { "en" : "-0.5+ mV T wave in V3/V4" ,  "ru" : "Зубец Т -0,5+ мВ в отведениях V3/V4" , "ua" : "Зубець Т -0,5+ мВ у відведеннях V3/V4" },
	"[S0169]" : { "en" : "T wave abnormality, possible lateral ischemia" ,  "ru" : "Аномалия зубца T, возможно латеральная ишемия" , "ua" : "Аномалія зубця T, можливо латеральна ішемія" },
	"[S0170]" : { "en" : "-0.1+ mV T wave in I/aVL/V5/V6" ,  "ru" : "-0,1+ мВ зубец Т в I/aVL/V5/V6" , "ua" : "-0,1+ мВ зубець Т I/aVL/V5/V6" },
	"[S0171]" : { "en" : "T wave abnormality, consistent with lateral ischemia" ,  "ru" : "Аномалия зубца T, соответствующая латеральной ишемии" , "ua" : "Аномалія зубця T, що відповідає латеральній ішемії" },
	"[S0172]" : { "en" : "-0.5+ mV T wave in I/aVL/V5/V6" ,  "ru" : "-0,5+ мВ зубец Т в I/aVL/V5/V6" , "ua" : "-0,5+ мВ зубець Т I/aVL/V5/V6" },
	"[S0173]" : { "en" : "T wave abnormality, possible anterolateral ischemia" ,  "ru" : "Аномалия зубца T, возможно переднебоковая ишемия" , "ua" : "Аномалія зубця T, можливо передньобокова ішемія" },
	"[S0174]" : { "en" : "-0.1+ mV T wave in V3-V6" ,  "ru" : "Зубец T -0,1+ мВ в V3-V6" , "ua" : "Зубець T -0,1+ мВ V3-V6" },
	"[S0175]" : { "en" : "T wave abnormality, consistent with anterolateral ischemia" ,  "ru" : "Аномалия зубца T, соответствующая переднебоковой ишемии" , "ua" : "Аномалія зубця T, що відповідає передньобоковій ішемії" },
	"[S0176]" : { "en" : "-0.5+ mV T wave in I/aVL/V3-V6" ,  "ru" : "-0,5+ мВ зубец Т в I/aVL/V3-V6" , "ua" : "-0,5+ мВ зубець Т I/aVL/V3-V6" },
	"[S0177]" : { "en" : "T wave abnormality, possible inferior ischemia" ,  "ru" : "Аномалия зубца T, возможно нижняя ишемия" , "ua" : "Аномалія зубця T, можливо нижня ішемія" },
	"[S0178]" : { "en" : "-0.1+ mV T wave in II/aVF" ,  "ru" : "Зубец T -0,1+ мВ в II/aVF" , "ua" : "Зубець T -0,1+ мВ II/aVF" },
	"[S0180]" : { "en" : "T wave abnormality, consistent with inferior ischemia" ,  "ru" : "Аномалия зубца T, соответствующая нижней ишемии" , "ua" : "Аномалія зубця T, що відповідає нижній ішемії." },
	"[S0181]" : { "en" : "-0.5+ mV T wave in II/aVF" ,  "ru" : "Зубец T -0,5+ мВ в II/aVF" , "ua" : "Зубець T -0,5+ мВ II/aVF" },
	"[S0182]" : { "en" : "T wave abnormality, non specific" ,  "ru" : "Неспецифическое изменение зубца Т" , "ua" : "Неспецифічна зміна зубця Т" },
	"[S0183]" : { "en" : "Abnormal QRS-T angle" ,  "ru" : "Аномальное расхождение осей QRS и Т" , "ua" : "Аномальна розбіжність осей QRS та Т" },
	"[S0184]" : { "en" : "QRS-T axis difference > 60" ,  "ru" : "Расхождение между осями QRS  и Т > 60 градусов" , "ua" : "Розбіжність між осями QRS та Т > 60 градусів" },
	"[S0185]" : { "en" : "Non specific T wave abnormality" ,  "ru" : "Неспецифическое нарушение зубца Т" , "ua" : "Неспецифічне порушення зубця Т" },
	"[S0186]" : { "en" : "Non specific ST & T wave abnormality" ,  "ru" : "Неспецифическое нарушение сегмента ST и зубца Т" , "ua" : "Неспецифічне порушення сегмента ST та зубця Т" },
	"[S0187]" : { "en" : "T Wave Abnormality Nonspecific probably digitalis effect" ,  "ru" : "Неспецифическое нарушкние зубца Т, вероятно эффект дигиталиса" , "ua" : "Неспецифічне порушення зубця Т, ймовірно ефект дигіталісу" },
	"[S0188]" : { "en" : "Tall T waves, possible hyperkalemia" ,  "ru" : "Высокие зубцы T, возможно гиперкалиемия" , "ua" : "Високі зубці T, можливо гіперкаліємія" },
	"[S0189]" : { "en" : "Short QT interval" ,  "ru" : "Короткий интервал QT" , "ua" : "Короткий інтервал QT" },
	"[S0190]" : { "en" : "Long QT interval" ,  "ru" : "Длинный интервал QT" , "ua" : "Довгий інтервал QT" },
	"[S0191]" : { "en" : "Brugada" ,  "ru" : "Синдром Бругада" , "ua" : "Синдром Бругада" },
	"[S0192]" : { "en" : "Type 3 Brugada pattern (non-diagnostic)" ,  "ru" : "Паттерн Бругада типа 3 (недиагностический)" , "ua" : "Паттерн Бругада типу 3 (недіагностичний)" },
	"[S0193]" : { "en" : "Type 2 Brugada pattern (non-diagnostic)" ,  "ru" : "Паттерн Бругада типа 2 (недиагностический)" , "ua" : "Паттерн Бругада типу 2 (недіагностичний)" },
	"[S0194]" : { "en" : "Type 1 Brugada pattern (non-diagnostic)" ,  "ru" : "Паттерн Бругада типа 1 (недиагностический)" , "ua" : "Паттерн Бругада типу 1 (недіагностичний)" },
}
