import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import './Header.css';
import {ExpandMore} from "@mui/icons-material";

interface AccountDropdownProps{
    userName: string;
    onProfileClick: () => void;
    onSettingsClick: () => void;
    onLogoutClick: () => void;
}

export function AccountDropdown ({ userName, onProfileClick, onSettingsClick, onLogoutClick }: AccountDropdownProps) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const profileClick = () => {
        setOpen(false);
        onProfileClick();
    };
    const settingsClick = () => {
        setOpen(false);
        onSettingsClick();
    };
    const logoutClick = () => {
        setOpen(false);
        onLogoutClick();
    };
    return (
    <div className="header-account-dropdown">
        <div className="header-profile-link disable-select" onClick={() => setOpen(!open)}>{userName} <ExpandMore/></div>
        <div className={`header-account-dropdown-menu ${open ? "header-account-dropdown-menu-show" : ""}`}>
            <div className="header-profile-link disable-select" onClick={profileClick}>{t("profile")}</div>
            <div className="header-profile-link disable-select" onClick={settingsClick}>{t("settings")}</div>
            <div className="header-profile-link disable-select" onClick={logoutClick}>{t("sign_out")}</div>
        </div>
    </div>
    );
}