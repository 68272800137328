import Footer from "../Widgets/Footer/Footer";
import React from "react";
import {Container} from "react-bootstrap";
import {Navigate, Routes, useLocation} from "react-router-dom";
import {Route} from "react-router";
import * as AppRoutes from "../../routes";
import {GuestRecordDetails} from "./GuestRecordDetails/GuestRecordDetails";
import {GuestEcgView} from "./GuestEcgView/GuestEcgView";
import {GuestLtEcgView} from "./GuestLtEcgView/GuestLtEcgView";
import {GuestSpiroView} from "./GuestSpiroView/GuestSpiroView";
import {GuestStethoscopeView} from "./GuestStethoscopeView/GuestStethoscopeView";
import GuestHeader from "../Widgets/Header/GuestHeader";

function Guest() {
    const location = useLocation();
    return (
        <Container fluid>
            <GuestHeader/>
            <Routes location={location}>
                <Route path={`:token`} element={<GuestRecordDetails />}/>
                <Route path={`:token/ecg`} element={<GuestEcgView />}/>
                <Route path={`/:token/lt-ecg`} element={<GuestLtEcgView />}/>
                <Route path={`/:token/spiro`} element={<Navigate to={`${location.pathname}/0`}/>}/>
                <Route path={`/:token/spiro/:tabIndex`} element={<GuestSpiroView />}/>
                <Route path={`/:token/stethoscope`} element={<Navigate to={`${location.pathname}/1`}/>}/>
                <Route path={`/:token/stethoscope/:tabIndex`} element={<GuestStethoscopeView />}/>
            </Routes>
            <Footer/>
        </Container>
    );
}

export default Guest;