import {TFunction} from "i18next";
import {SpiroBriefResult} from "../models/SpiroBriefResult";
import numeral from "numeral";

function getRateDescription(t: TFunction<"translation">, rate : number) {
    switch (rate) {
        case 1:
            return t('minor');
        case 2:
            return t('medium_hard');
        case 3:
            return t('hard');
        case 4:
            return t('extremely_hard');
        default:
            return "";
    }
}

export function makeSpiroConclusion(t: TFunction<"translation">, briefResult : SpiroBriefResult) : string | undefined{
    const fvcResult = briefResult.fvcResult;
    if (fvcResult) {
        const fvc = fvcResult.fvc;
        const fev1 = fvcResult.fev1;
        if (fvc && fev1) {
            let obstructionRate = 0;
            if (fev1.percentPredicted < 70) {
                obstructionRate = 1;
            }
            if (fev1.percentPredicted < 60) {
                obstructionRate = 2;
            }
            if (fev1.percentPredicted < 50) {
                obstructionRate = 3;
            }
            if (fev1.percentPredicted < 35) {
                obstructionRate = 4;
            }

            let restrictionRate = 0;
            if (fvc.percentPredicted < 80) {
                restrictionRate = 1;
            }
            if (fvc.percentPredicted < 70) {
                restrictionRate = 2;
            }
            if (fvc.percentPredicted < 60) {
                restrictionRate = 3;
            }
            if (fvc.percentPredicted < 40) {
                restrictionRate = 4;
            }
            if (obstructionRate === 0 && restrictionRate === 0) {
                return t('spiro_conclusion_normal');
            } else if (obstructionRate !== 0 && restrictionRate !== 0) {
                return t('spiro_conclusion_mixed_format', {obstructiveRate : getRateDescription(t, obstructionRate), restrictiveRate : getRateDescription(t, restrictionRate), fev1: numeral(fev1.percentPredicted).format('0.00'), fvc: numeral(fvc.percentPredicted).format('0.00')});
            } else if (obstructionRate !== 0) {
                return t('spiro_conclusion_obstructed_format', {obstructiveRate : getRateDescription(t, obstructionRate), fev1: numeral(fev1.percentPredicted).format('0.00'), fvc: numeral(fvc.percentPredicted).format('0.00')});
            } else {
                return t('spiro_conclusion_restrictive_format', {restrictiveRate : getRateDescription(t, restrictionRate), fev1: numeral(fev1.percentPredicted).format('0.00'), fvc: numeral(fvc.percentPredicted).format('0.00')});
            }
        }
    }
    return undefined;
}