import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {calculateLeads, getLeadName, getNumberOfLeads} from "../../../ecg/EcgHelper";
import {PX_PER_MM} from "../EcgViewer/EcgViewer";
import {filterEcgData} from "../../../ecg/EcgFilter";
import {PlotPointsBuilder} from "../../../helpers/PlotPointsBuilder";
import {getStyledColor} from "../../../helpers/DrawHelper";
import {Group, Layer, Line, Rect, Stage, Text} from "react-konva";
import {LtEcgData} from "../../../models/LtEcgData";
import {LtEcgFileHeader} from "../../../models/LtEcgFileHeader";
import Konva from "konva";
import KonvaEventObject = Konva.KonvaEventObject;
import Vector2d = Konva.Vector2d;

const MM_PER_LEAD = 20;

const MAJOR_LINE_WIDTH_PX = 1.2;
const MINOR_LINE_WIDTH_PX = 1;
const MILLI_VOLT_LINE_WIDTH_PX = 2;
const MILLI_VOLT_TEXT_SIZE_MM = 2.5;
const SCALE_DATA_TEXT_SIZE_MM = 3.5;
const RULER_DATA_TEXT_SIZE_MM = 3.5;
const LEGEND_OPACITY = 0.75;
const DATA_LINE_WIDTH = 1.5;
const QRS_MARK_LINE_WIDTH = 1.5;
const QRS_INTERVAL_TEXT_SIZE_MM = 3.5;

interface Point {
    x: number;
    y: number | null;
}

interface QrsMark {
    x: number;
    color: string;
}

interface QrsInterval {
    x: number;
    duration: number;
    value: string;
    color: string;
}

interface Props {
    width: number;
    numberOfSamples: number;
    samplesPerScreen: number;
    firstSample: number;
    lastSample: number;
    ecgData: LtEcgData | undefined;
    header: LtEcgFileHeader;
    mvScale: number;
    timeScale: number;
    lpf: boolean;
    hpf: boolean;
    ruler: boolean;
    onScrollPositionChanged: ((position: number) => void);
}

const X_SCROLL_REGION = 15;
const Y_SCROLL_REGION = 5;

export const LtEcgMonitor: React.FC<Props> = ({
                                                  width,
                                                  numberOfSamples,
                                                  samplesPerScreen,
                                                  firstSample,
                                                  lastSample,
                                                  ecgData,
                                                  header,
                                                  mvScale,
                                                  timeScale,
                                                  lpf,
                                                  hpf,
                                                  ruler,
                                                  onScrollPositionChanged
                                              }: Props) => {
    const {t} = useTranslation();
    const [rulerStart, setRulerStart] = useState(null as Point | null);
    const [rulerEnd, setRulerEnd] = useState(null as Point | null);
    const [rulerActive, setRulerActive] = useState(false);
    useEffect(() => {
        const listener = () => {
            setRulerActive(false);
        };
        document.addEventListener('mouseup', listener);
        return () => {
            document.removeEventListener('mouseup', listener);
        }
    })
    useEffect(() => {
        if (!ruler) {
            setRulerStart(null);
            setRulerEnd(null);
            setRulerActive(false);
        }
    }, [ruler]);
    const isEasi = (header.sensorMetadata.sensorConfig ?? 0) === 1;
    const numberOfLeads = useMemo(() => getNumberOfLeads(isEasi, header.sensorMetadata.channelCount), [header.sensorMetadata.channelCount]); // eslint-disable-line
    const height = useMemo(() => (numberOfLeads ?? 0) * MM_PER_LEAD * PX_PER_MM, [numberOfLeads]); // eslint-disable-line
    const filteredLeadsData = useMemo(() => {
        if (ecgData) {
            const filteredData = filterEcgData(header.sensorMetadata.channelCount, ecgData.ecg, lpf, hpf);
            const leadsData = new Array<Array<number | null>>();
            for (let i = 0; i < ecgData.ecg[0].length; i++) {
                const input = new Array<number | null>();
                for (let j = 0; j < header.sensorMetadata.channelCount; j++) {
                    input.push(filteredData[i * header.sensorMetadata.channelCount + j]);
                }
                leadsData.push(calculateLeads(isEasi, header.sensorMetadata.channelCount, input));
            }
            return leadsData;
        } else {
            return null;
        }
    }, [ecgData, header, lpf, hpf]); // eslint-disable-line
    const mvScaledData = useMemo(() => {
        if (filteredLeadsData) {
            const scaleFactor = -1 * mvScale * PX_PER_MM / header.sensorMetadata.unitsPerMv
            return filteredLeadsData.map(samples => samples.map(sample => (sample !== null ? sample * scaleFactor : null)));
        } else {
            return null;
        }
    }, [filteredLeadsData, ecgData, header, mvScale]); // eslint-disable-line
    const timeScaleFactor = useMemo(() => timeScale * PX_PER_MM / header.sensorMetadata.samplingRate, [timeScale, header]); // eslint-disable-line
    const timeScaledData = useMemo(() => {
        if (mvScaledData) {
            const points = new Array<Array<Point>>();
            for (let i = 0; i < (numberOfLeads ?? 0); i++) {
                const builder = new PlotPointsBuilder();
                for (let j = 0; j < ecgData!.ecg[0].length; j++) {
                    builder.addData((firstSample + j) * timeScaleFactor, mvScaledData[j][i]);
                }
                points.push(builder.build());
            }
            return points;
        } else {
            return null;
        }
    }, [mvScaledData, timeScaleFactor, numberOfLeads, numberOfSamples]); // eslint-disable-line
    const drawPoints = useMemo(() => {
        if (timeScaledData) {
            const startX = firstSample * timeScaleFactor;
            const endX = lastSample * timeScaleFactor;
            const points = new Array<Array<number>>();
            for (let i = 0; i < (numberOfLeads ?? 0); i++) {
                let previousPoints = new Array<number>();
                const channelPoints = new Array<number>();
                const offsetY = (i + 0.5) * MM_PER_LEAD * PX_PER_MM;
                const channel = timeScaledData[i];
                let isFirst = true;
                for (let j = 0; j < channel.length; j++) {
                    const point = channel[j];
                    if (point.x >= startX && point.x <= endX) {
                        if (isFirst) {
                            channelPoints.push(...previousPoints)
                            isFirst = false;
                        }
                        channelPoints.push(point.x - startX, point.y !== null ? point.y + offsetY : Number.NaN);
                    }
                    if (point.x > endX) {
                        channelPoints.push(point.x - startX, point.y !== null ? point.y + offsetY : Number.NaN);
                        if (point.y !== null) {
                            break;
                        }
                    }
                    if (point.x < startX) {
                        if (point.y !== null) {
                            previousPoints = new Array<number>();
                        }
                        previousPoints.push(point.x - startX, point.y !== null ? point.y + offsetY : Number.NaN);
                    }
                }
                points.push(channelPoints);
            }
            return points;
        } else {
            return null;
        }
    }, [firstSample, lastSample, timeScaledData, timeScaleFactor, numberOfLeads]); // eslint-disable-line
    const [dataPoints, nullPoints] = useMemo(() => {
        if (drawPoints) {
            const dataPoints = new Array<Array<Array<number>>>();
            const nullPoints = new Array<Array<Array<number>>>();
            for (let i = 0; i < (numberOfLeads ?? 0); i++) {
                const offsetY = (i + 0.5) * MM_PER_LEAD * PX_PER_MM;
                dataPoints.push(new Array<Array<number>>());
                nullPoints.push(new Array<Array<number>>());
                let lastValuedPoint: Array<number> | undefined = undefined;
                let dataPath = new Array<number>();
                let nullPath = new Array<number>();
                for (let j = 0; j < drawPoints[i].length; j += 2) {
                    const x = drawPoints[i][j];
                    const y = drawPoints[i][j + 1];
                    if (!isNaN(y)) {
                        dataPath.push(x, y);
                        lastValuedPoint = new Array<number>(x, y);
                        if (nullPath.length > 0) {
                            nullPath[2] = x;
                            nullPath[3] = y;
                            nullPoints[i].push(nullPath);
                            nullPath = new Array<number>();
                        }
                    } else {
                        if (dataPath.length >= 4) {
                            dataPoints[i].push(dataPath);
                        }
                        if (nullPath.length === 0) {
                            if (lastValuedPoint) {
                                nullPath.push(...lastValuedPoint);
                            } else {
                                nullPath.push(x, offsetY);
                            }
                            nullPath.push(x, offsetY);
                        } else {
                            nullPath[2] = x;
                            nullPath[3] = offsetY;
                        }
                        dataPath = new Array<number>();
                    }
                }
                if (dataPath.length >= 4) {
                    dataPoints[i].push(dataPath);
                }
                if (nullPath.length >= 4) {
                    nullPoints[i].push(nullPath);
                }
            }
            return [dataPoints, nullPoints];
        } else {
            return [null, null];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawPoints]);
    const [qrsMarksData, qrsIntervalsData] = useMemo(() => {
        const marks = new Array<QrsMark>();
        const intervals = new Array<QrsInterval>();
        if (ecgData?.qrs) {
            const startX = firstSample * timeScaleFactor;
            let previousMark: undefined | QrsMark = undefined;
            for (const qrs of ecgData?.qrs) {
                let color;
                switch (qrs.templateType) {
                    case 1:
                        color = "rgb(0,127,127)";
                        break;
                    case 2:
                        color = "rgb(0,0,127)";
                        break;
                    case 3:
                        color = "rgb(0,0,255)";
                        break;
                    case 4:
                        color = "rgb(127,0,0)";
                        break;
                    case 5:
                        color = "rgb(128,0,127)";
                        break;
                    case 6:
                        color = "rgb(0,127,0)";
                        break;
                    case 16:
                        color = "rgb(96,96,96)";
                        break;
                    case 17:
                        color = "rgb(0,127,127)";
                        break;
                    case 18:
                        color = "rgb(127,0,0)";
                        break;
                    case 19:
                        color = "rgb(0,0,255)";
                        break;
                    case 20:
                    default:
                        color = "rgb(64,64,64)";
                        break;
                }
                const mark = {
                    x: qrs.samplePosition * timeScaleFactor - startX,
                    color: color
                } as QrsMark;
                marks.push(mark);
                if (previousMark) {
                    intervals.push({
                        x: previousMark.x,
                        duration: mark.x - previousMark.x,
                        value: "" + qrs.rrValue,
                        color: color
                    })
                }
                previousMark = mark;
            }
        }
        return [marks, intervals];
    }, [ecgData?.qrs, timeScaleFactor, firstSample]);
    const hrValue = useMemo(() => {
        if (ecgData?.qrs) {
            if (ecgData.qrs.length > 1 && header.sensorMetadata.samplingRate > 0) {
                const duration = (ecgData.qrs[ecgData.qrs.length - 1].samplePosition - ecgData.qrs[0].samplePosition) / header.sensorMetadata.samplingRate / 60;
                if (duration > 0){
                    return (ecgData.qrs.length - 1) / duration;
                }
            }
        }
        return undefined;
    }, [ecgData?.qrs, header.sensorMetadata.samplingRate]);
    let base = null;
    let border = null;
    let majorGridLines = null;
    let minorGridLines = null;
    let scaleInfo = null;
    let milliVolts = null;
    let qrsMarks = null;
    let qrsIntervals = null;
    let hrText = null;
    let leadsDataLines = null;
    let leadsNullLines = null;
    let rulerData = null;
    let rulerInfo = null;
    let rulerText = null;
    if (numberOfLeads && width > 0 && height > 0) {
        const backgroundColor = getStyledColor("--ecg-background");
        const gridLineColor = getStyledColor("--ecg-grid-line");
        const contentColors = [getStyledColor("--ecg-content-1"), getStyledColor("--ecg-content-2"), getStyledColor("--ecg-content-3")];
        const nullColor = getStyledColor("--ecg-content-null");
        const scaleDataColor = getStyledColor("--primary-text");
        const rulerDataColor = getStyledColor("--primary-text");
        base = <Rect width={width} height={height} x={0} y={0} strokeEnabled={false} fill={backgroundColor}
                     fillEnabled={true}/>
        border =
            <Rect width={width} height={height} x={0} y={0} strokeWidth={MAJOR_LINE_WIDTH_PX} stroke={gridLineColor}/>
        majorGridLines = [];
        minorGridLines = [];
        const gridStep = 5 * PX_PER_MM;
        let iX = 1;
        for (let x = gridStep; x < width; x += gridStep) {
            if (iX % 2 === 0) {
                majorGridLines.push(<Line key={`h${iX}`} points={[x, 0, x, height]} strokeWidth={MAJOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            } else {
                minorGridLines.push(<Line key={`h${iX}`} points={[x, 0, x, height]} strokeWidth={MINOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            }
            iX++;
        }
        let iY = 1;
        for (let y = gridStep; y < height; y += gridStep) {
            if (iY % 2 === 0) {
                majorGridLines.push(<Line key={`v${iY}`} points={[0, y, width, y]} strokeWidth={MAJOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            } else {
                minorGridLines.push(<Line key={`v${iY}`} points={[0, y, width, y]} strokeWidth={MINOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            }
            iY++;
        }
        let scaleData = t("ecg_scale_data_format", {mvScale: mvScale.toFixed(1), timeScale: timeScale.toFixed(1)});
        if (isEasi) {
            scaleData = `EASI     ${scaleData}`;
        }
        const scaleDataTextSize = SCALE_DATA_TEXT_SIZE_MM * PX_PER_MM;
        scaleInfo = <Text x={0} y={1.25 * PX_PER_MM} fill={scaleDataColor} opacity={0.5}
                          fontStyle={"bold"} fontSize={scaleDataTextSize} width={width - 5 * PX_PER_MM} align={"right"}
                          text={scaleData}/>;
        const mvX1 = 0;
        const mvY1 = 0;
        const mvX2 = 1.5 * PX_PER_MM;
        const mvY2 = -mvScale * PX_PER_MM;
        const mvX3 = 3.5 * PX_PER_MM;
        const mvX4 = 5 * PX_PER_MM;
        milliVolts = [];
        for (let lead = 0; lead < numberOfLeads; lead++) {
            const name = getLeadName(lead);
            const offsetY = (lead + 0.5) * MM_PER_LEAD * PX_PER_MM;
            const color = contentColors[lead % contentColors.length];
            milliVolts.push(
                <Group key={`mvg-${lead}`}>
                    <Line key={`mv-${lead}`}
                          points={[mvX1, mvY1 + offsetY, mvX2, mvY1 + offsetY, mvX2, mvY2 + offsetY, mvX3, mvY2 + offsetY, mvX3, mvY1 + offsetY, mvX4, mvY1 + offsetY]}
                          strokeWidth={MILLI_VOLT_LINE_WIDTH_PX} opacity={LEGEND_OPACITY}
                          stroke={color}/>
                    <Text key={`mvt-${lead}`} x={0} width={5 * PX_PER_MM} y={offsetY + 2 * PX_PER_MM} text={name}
                          fill={color} opacity={LEGEND_OPACITY} fontStyle={"bold"}
                          fontSize={MILLI_VOLT_TEXT_SIZE_MM * PX_PER_MM} align={"center"}/>
                </Group>
            );
        }
        qrsMarks = qrsMarksData.map((mark, i) => <Line key={`qrs-m${i}`}
                                                       points={[mark.x, height - 5 * PX_PER_MM, mark.x, height]}
                                                       strokeWidth={QRS_MARK_LINE_WIDTH}
                                                       stroke={mark.color} opacity={0.5}/>);
        const qrsIntervalTextSize = QRS_INTERVAL_TEXT_SIZE_MM * PX_PER_MM;
        qrsIntervals = qrsIntervalsData.map((interval, i) => <Text key={`qrs-i${i}`} x={interval.x}
                                                                   y={height - 0.25 * PX_PER_MM - qrsIntervalTextSize}
                                                                   fill={interval.color} opacity={0.5}
                                                                   fontStyle={"bold"} fontSize={qrsIntervalTextSize}
                                                                   width={interval.duration} align={"center"}
                                                                   text={interval.value}/>);
        if (hrValue){
            hrText = <Text x={5 * PX_PER_MM} y={1.25 * PX_PER_MM} fill={scaleDataColor} opacity={0.5}
                           fontStyle={"bold"} fontSize={scaleDataTextSize} width={width - 5 * PX_PER_MM} align={"left"}
                           text={`${Math.round(hrValue)} ${t('units_bpm')}`}/>;
        }
        leadsDataLines = dataPoints?.flatMap((lines, lead) => lines.map((points, i) => <Line key={`ld-${lead}-${i}`}
                                                                                             points={points}
                                                                                             strokeWidth={DATA_LINE_WIDTH}
                                                                                             stroke={contentColors[lead % contentColors.length]}/>));
        leadsNullLines = nullPoints?.flatMap((lines, lead) => lines.map((points, i) => <Line key={`ln-${lead}-${i}`}
                                                                                             points={points}
                                                                                             strokeWidth={DATA_LINE_WIDTH}
                                                                                             stroke={nullColor}/>));
        if (ruler && rulerStart && rulerEnd) {
            const x1 = (rulerStart.x - firstSample) / header.sensorMetadata.samplingRate * timeScale * PX_PER_MM;
            const y1 = rulerStart.y ?? 0;
            const x2 = (rulerEnd.x - firstSample) / header.sensorMetadata.samplingRate * timeScale * PX_PER_MM;
            const y2 = rulerEnd.y ?? 0;
            const w = x2 - x1;
            const h = y2 - y1;
            const ms = Math.round(Math.abs(rulerStart.x - rulerEnd.x) / header.sensorMetadata.samplingRate * 1000);
            const bpm = Math.round(60 / (ms / 1000));
            const mkv = Math.round(Math.abs(y1 - y2) / PX_PER_MM / mvScale * 1000);
            const text = `${ms} ${t('units_ms')}${(bpm >= 20 && bpm <= 300) ? ` (${bpm} ${t('units_bpm')})` : ''}   ${mkv} ${t('units_mkv')}`;
            const tw = 300;
            let tx = w > 0 ? x1 : (x1 - tw);
            let align = w > 0 ? 'left' : 'right';
            let ty = h < 0 ? (y1 + 2 * PX_PER_MM) : (y1 - RULER_DATA_TEXT_SIZE_MM * PX_PER_MM);
            if (ty < RULER_DATA_TEXT_SIZE_MM * PX_PER_MM) {
                ty = Math.max(y1, y2) + 2 * PX_PER_MM;
            }
            if (ty > height - RULER_DATA_TEXT_SIZE_MM * PX_PER_MM) {
                ty = Math.min(y1, y2) - RULER_DATA_TEXT_SIZE_MM * PX_PER_MM;
            }
            const rulerDataMouseEnterHandler = (evt: KonvaEventObject<MouseEvent>) => {
                if (!rulerActive) {
                    const container = evt.target?.getStage()?.container();
                    if (container) {
                        container.style.cursor = "move";
                    }
                }
            };
            const rulerDataMouseLeaveHandler = (evt: KonvaEventObject<MouseEvent>) => {
                const container = evt.target?.getStage()?.container();
                if (container) {
                    container.style.cursor = "crosshair";
                }
            };
            const rulerDataDragBoundFunc = (pos: Vector2d) => {
                let posX = pos.x;
                let posY = pos.y;
                let xd1 = firstSample + pos.x / PX_PER_MM / timeScale * header.sensorMetadata.samplingRate;
                let yd1 = pos.y;
                let xd2 = xd1 + (rulerEnd.x - rulerStart.x);
                let yd2 = yd1 + ((rulerEnd.y ?? 0) - (rulerStart.y ?? 0));
                const leftOverScreenX = Math.min(xd1, xd2);
                const rightOverScreenX = Math.max(xd1, xd2) - numberOfSamples;
                if (leftOverScreenX < 0) {
                    xd1 -= leftOverScreenX;
                    xd2 -= leftOverScreenX;
                    posX -= leftOverScreenX / header.sensorMetadata.samplingRate * timeScale * PX_PER_MM;
                }
                if (rightOverScreenX > 0) {
                    xd1 -= rightOverScreenX;
                    xd2 -= rightOverScreenX;
                    posX -= rightOverScreenX / header.sensorMetadata.samplingRate * timeScale * PX_PER_MM;
                }
                const topOverScreenY = Math.min(yd1, yd2);
                const bottomOverScreenY = Math.max(yd1, yd2) - height;
                if (topOverScreenY < 0) {
                    yd1 -= topOverScreenY;
                    yd2 -= topOverScreenY;
                    posY -= topOverScreenY;
                }
                if (bottomOverScreenY > 0) {
                    yd1 -= bottomOverScreenY;
                    yd2 -= bottomOverScreenY;
                    posY -= bottomOverScreenY;
                }
                const start: Point = {
                    x: xd1,
                    y: yd1
                };
                const end: Point = {
                    x: xd2,
                    y: yd2
                };
                setRulerStart(start);
                setRulerEnd(end);
                return {
                    x: posX,
                    y: posY
                };
            };
            rulerData =
                <Rect x={x1} y={y1} width={w} height={h} strokeEnabled={true} dashEnabled={true} dash={[5, 5]}
                      strokeWidth={1} stroke={rulerDataColor} opacity={0.75}
                      draggable={!rulerActive}
                      dragBoundFunc={rulerDataDragBoundFunc}
                      onMouseEnter={rulerDataMouseEnterHandler}
                      onMouseLeave={rulerDataMouseLeaveHandler}
                      onMouseMove={rulerDataMouseEnterHandler}
                      onMouseDown={(e) => e.cancelBubble = true}/>;
            rulerInfo =
                <Text x={tx} width={tw} y={ty} text={text}
                      fill={rulerDataColor} opacity={0.75} fontStyle={"bold"}
                      fontSize={RULER_DATA_TEXT_SIZE_MM * PX_PER_MM} align={align}/>;
            rulerText = <Text x={5 * PX_PER_MM} y={height - 0.75 * PX_PER_MM - scaleDataTextSize} fill={scaleDataColor}
                              opacity={0.5}
                              fontStyle={"bold"} fontSize={scaleDataTextSize} width={width - 5 * PX_PER_MM}
                              align={"left"}
                              text={text}/>;
        }
    }
    const mouseDownHandler = (evt: KonvaEventObject<MouseEvent>) => {
        if (ruler) {
            setRulerEnd(null);
            setRulerStart({
                x: firstSample + evt.evt.offsetX / timeScale / PX_PER_MM * header.sensorMetadata.samplingRate,
                y: evt.evt.offsetY
            });
            setRulerActive(true);
        }
    };
    const mouseMoveHandler = (evt: KonvaEventObject<MouseEvent>) => {
        if (ruler && rulerStart && rulerActive) {
            setRulerEnd({
                x: firstSample + evt.evt.offsetX / timeScale / PX_PER_MM * header.sensorMetadata.samplingRate,
                y: evt.evt.offsetY
            });
            if (evt.evt.offsetX < X_SCROLL_REGION * PX_PER_MM) {
                onScrollPositionChanged(Math.max(0, firstSample - X_SCROLL_REGION / timeScale / PX_PER_MM * header.sensorMetadata.samplingRate));
            }
            if (evt.evt.offsetX > (width - X_SCROLL_REGION * PX_PER_MM)) {
                onScrollPositionChanged(Math.min(numberOfSamples - samplesPerScreen, firstSample + X_SCROLL_REGION / timeScale / PX_PER_MM * header.sensorMetadata.samplingRate));
            }
            if (evt.evt.clientY < Y_SCROLL_REGION * PX_PER_MM) {
                window.scrollBy(0, -Y_SCROLL_REGION * PX_PER_MM);
            }
            if (evt.evt.clientY > (window.innerHeight - 2 * Y_SCROLL_REGION * PX_PER_MM)) {
                window.scrollBy(0, Y_SCROLL_REGION * PX_PER_MM);
            }
        }
    };
    const mouseEnterHandler = (evt: KonvaEventObject<MouseEvent>) => {
        if (ruler) {
            const container = evt.target?.getStage()?.container();
            if (container) {
                container.style.cursor = "crosshair";
            }
        }
    };
    const mouseLeaveHandler = (evt: KonvaEventObject<MouseEvent>) => {
        const container = evt.target?.getStage()?.container();
        if (container) {
            container.style.cursor = "default";
        }
    };
    return (
        <Stage width={width} height={height}>
            <Layer onMouseDown={mouseDownHandler}
                   onMouseMove={mouseMoveHandler}
                   onMouseEnter={mouseEnterHandler}
                   onMouseLeave={mouseLeaveHandler}>
                {base ?? base}
                {minorGridLines && minorGridLines}
                {majorGridLines && majorGridLines}
                {border && border}
                {milliVolts && milliVolts}
                {leadsDataLines && leadsDataLines}
                {leadsNullLines && leadsNullLines}
                {qrsMarks && qrsMarks}
                {qrsIntervals && qrsIntervals}
                {hrText && hrText}
                {scaleInfo && scaleInfo}
                {rulerText && rulerText}
                {rulerData && rulerData}
                {rulerInfo && rulerInfo}
            </Layer>
        </Stage>
    );
}