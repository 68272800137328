import React from "react";
import {formatAgeShort, formatDateTime, formatGenderShort, formatPatientName} from "../../../helpers/FormatHelper";
import {Record} from '../../../models/Record';
import {StudyIcon, StudyType} from "../StudyIcon/StudyIcon";
import {useTranslation} from "react-i18next";
import {getUserPhotoUrl} from "../../../api/ApiHelper";
import {Gender} from "../../../models/Gender";
import {Avatar} from "@mui/material";

interface RecordProps {
    record: Record;
    onClick: () => void;
    onContextMenuHandler: (anchorX: number, anchorY: number) => void;
    onStudyClick: (type: StudyType) => void;
    onUserClick?: (userId: string) => void;
}

export const RecordsLong: React.FC<RecordProps> = ({ record, onClick, onContextMenuHandler, onStudyClick, onUserClick }: RecordProps) => {
    const {t} = useTranslation();
    const contextMenuHandler = (event : any) => {
        event.preventDefault();
        onContextMenuHandler(event.clientX, event.clientY);
    };
    const userClickHandler = (e : React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (onUserClick && record.ownerInfo?.ownerId){
            onUserClick(record.ownerInfo.ownerId);
        }
    };
    return (
        <tr onClick={onClick} onContextMenu={contextMenuHandler}>
            {record.ownerInfo &&
                <td align="left" className="d-flex justify-content-start align-items-center text-left">
                    <Avatar className="user-avatar-img mx-2" alt={record.ownerInfo.ownerName} src={getUserPhotoUrl(record.ownerInfo.ownerPhotoUrl)}/>
                    <div className={`text-tooltip ${onUserClick ? "clickable" : ""}`} onClick={userClickHandler}>{record.ownerInfo.ownerName}<span>{record.ownerInfo.ownerEmail}</span></div>
                </td>
            }
            <td align="center" className="text-left">{formatPatientName(t, record.patientId === null, record.patientName)}</td>
            <td align="center" className="text-center">{`${record.gender !== Gender.Undefined ? `${formatGenderShort(t, record.gender)} · ` : ""}${formatAgeShort(record.age)}`}</td>
            <td align="center">{formatDateTime(record.dateTime)}</td>
            <td align="center">
                <div className="d-flex align-items-center justify-content-center">
                    <StudyIcon type={StudyType.State} hasText={true} isVisible={record.state !== null} onClick={() => onStudyClick(StudyType.State)}/>
                </div>
            </td>
            <td align="center">
                <div className="d-flex align-items-center justify-content-center">
                    <StudyIcon type={StudyType.Ecg} hasText={true} isVisible={record.ecgStudy !== null} onClick={() => onStudyClick(StudyType.Ecg)}/>
                </div>
            </td>
            <td align="center">
                <div className="d-flex align-items-center justify-content-center">
                    <StudyIcon type={StudyType.Spiro} hasText={true} isVisible={record.spiroStudy !== null} onClick={() => onStudyClick(StudyType.Spiro)}/>
                </div>
            </td>
            <td align="center">
                <div className="d-flex align-items-center justify-content-center">
                    <StudyIcon type={StudyType.Stethoscope} hasText={true} isVisible={record.stethoscopeStudy !== null} onClick={() => onStudyClick(StudyType.Stethoscope)}/>
                </div>
            </td>
            <td align="center">
                <div className="d-flex align-items-center justify-content-center">
                    <StudyIcon type={StudyType.LtEcg} hasText={true} isVisible={record.ltEcgStudy !== null} onClick={() => onStudyClick(StudyType.LtEcg)}/>
                </div>
            </td>
        </tr>
    );
}