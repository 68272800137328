import React, {Fragment, useMemo, useState} from "react";
import {Layer, Rect, Stage} from "react-konva";
import {getStyledColor} from "../../../helpers/DrawHelper";
import {formatLtEcgTime} from "../../../helpers/FormatHelper";
import './LtEcgHorizontalScrollbar.css'
import Konva from "konva";
import KonvaEventObject = Konva.KonvaEventObject;
import Vector2d = Konva.Vector2d;

const SCROLL_BAR_HEIGHT = 12;
const CORNER_RADIUS = 4;
const MIN_THUMB_WIDTH = 2;

interface Props {
    width: number;
    position: number;
    max: number;
    screen: number;
    onScrollPositionChanged?: ((position: number) => void) | null;
    samplingRate: number;
    referenceTime: number;
}

export const LtEcgHorizontalScrollbar: React.FC<Props> = ({
                                                              width,
                                                              position,
                                                              max,
                                                              screen,
                                                              onScrollPositionChanged = null,
                                                              samplingRate,
                                                              referenceTime
                                                          }: Props) => {
    const trackColor = getStyledColor("--scroll-bar-track");
    const thumbColor = getStyledColor("--scroll-bar-thumb");
    const thumbPosition = useMemo(() => width / max * position, [width, max, position]);
    const thumbWidth = useMemo(() => Math.max(MIN_THUMB_WIDTH, width / max * screen), [width, max, screen]);
    const [tooltipText, setTooltipText] = useState(null as string | null);
    const [tooltipPosition, setTooltipPosition] = useState(0);
    const mouseMoveFunc = (evt: KonvaEventObject<MouseEvent>) => {
        const scrollWidth = width - thumbWidth;
        const maxScroll = max - screen;
        const x = evt.evt.offsetX;
        const mousePosition = Math.floor(x / scrollWidth * maxScroll);
        setTooltipPosition(x - 18);
        setTooltipText(formatLtEcgTime(referenceTime + mousePosition / samplingRate * 1000))
    }
    const mouseLeaveFunc = (evt: KonvaEventObject<MouseEvent>) => {
        setTooltipText(null);
    }
    const dragBoundFunc = (pos: Vector2d) => {
        const scrollWidth = width - thumbWidth;
        const maxScroll = max - screen;
        const boundX = Math.round(Math.min(Math.max(0, pos.x), scrollWidth));
        const newPosition = Math.floor(boundX / scrollWidth * maxScroll);
        const newX = newPosition / maxScroll * scrollWidth;
        if (onScrollPositionChanged) {
            onScrollPositionChanged(newPosition);
        }
        return {
            x: newX,
            y: 0
        }
    };
    const onTrackClick = (evt: KonvaEventObject<MouseEvent>) => {
        if (onScrollPositionChanged) {
            const scrollWidth = width - thumbWidth;
            const maxScroll = max - screen;
            const x = evt.evt.offsetX;
            const clickPosition = Math.floor(x / scrollWidth * maxScroll);
            onScrollPositionChanged(clickPosition);
        }
    }
    return (
        <Fragment>
            <Stage width={width} height={SCROLL_BAR_HEIGHT}>
                <Layer>
                    <Rect x={0} y={0} width={width} height={SCROLL_BAR_HEIGHT} cornerRadius={CORNER_RADIUS}
                          fill={trackColor} onClick={onTrackClick} onMouseMove={mouseMoveFunc}
                          onMouseLeave={mouseLeaveFunc}/>
                    <Rect x={thumbPosition} y={0} width={thumbWidth} height={SCROLL_BAR_HEIGHT}
                          cornerRadius={CORNER_RADIUS}
                          fill={thumbColor} draggable={true} dragBoundFunc={dragBoundFunc}/>
                </Layer>
            </Stage>
            <span className="scrollbar-tooltip"
                  style={{opacity: tooltipText ? 1 : 0, left: tooltipPosition}}>{tooltipText}</span>
        </Fragment>
    );
}