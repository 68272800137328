import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useAbortController, useAppSelector} from "../../../../hooks";
import {selectToken} from "../../../../features/account/accountSlice";
import React, {Fragment, useEffect, useState} from "react";
import {Breadcrumbs} from "../../../Widgets/Breadcrumbs/Breadcrumbs";
import Loader from "../../../Widgets/Loader/Loader";
import {FetchError} from "../../../Widgets/FetchError/FetchError";
import {Statistic} from "../../../../models/Statistic";
import {getStatistic} from "../../../../api/ApiHelper";
import {logError} from "../../../../helpers/LogHelper";
import {Button} from "../../../Widgets/Button/Button";
import {ADMIN_ROUTE} from "../../../../routes";

function formatSignedNumber(v: number): string {
    if (v <= 0) {
        return `${v}`;
    } else {
        return `+${v}`;
    }
}

export function StatisticView() {
    const {t} = useTranslation();
    const history = useNavigate();
    const token = useAppSelector(selectToken);
    const [controller] = useAbortController();
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [statistic, setStatistic] = useState(undefined as Statistic | undefined);
    const fetchData = () => {
        if (token) {
            setFetchingState(true);
            getStatistic(token.token, controller, data => {
                setStatistic(data);
                setErrorState(false);
                setFetchingState(false);
            }, error => {
                logError("Demo data info fetch error", error);
                setFetchingState(false);
                setErrorState(true);
            });
        }
    };
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<div>
        <Breadcrumbs/>
        {isFetching && <Loader/>}
        {!isFetching && hasError &&
            <FetchError onRetry={() => fetchData()}/>
        }
        {!isFetching && !hasError && statistic &&
            <Fragment>
                <div className="data-list-container">
                    <div className="data-list">
                        <div className="data-list-title">{t("statistic")}</div>
                        <table>
                            <thead>
                            <tr className="table-header">
                                <th/>
                                <th>{t("total_count")}</th>
                                <th>{t("new_today_count")}</th>
                                <th>{t("new_last_7_days_count")}</th>
                                <th>{t("new_last_30_days_count")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th>{t("users")}</th>
                                <td>{statistic.usersTotal}</td>
                                <td>{`${statistic.usersToday} (${formatSignedNumber(statistic.usersTodayDiff)})`}</td>
                                <td>{`${statistic.usersWeek} (${formatSignedNumber(statistic.usersWeekDiff)})`}</td>
                                <td>{`${statistic.usersMonth} (${formatSignedNumber(statistic.usersMonthDiff)})`}</td>
                            </tr>
                            <tr>
                                <th>{t("patients")}</th>
                                <td>{statistic.patientsTotal}</td>
                                <td>{`${statistic.patientsToday} (${formatSignedNumber(statistic.patientsTodayDiff)})`}</td>
                                <td>{`${statistic.patientsWeek} (${formatSignedNumber(statistic.patientsWeekDiff)})`}</td>
                                <td>{`${statistic.patientsMonth} (${formatSignedNumber(statistic.patientsMonthDiff)})`}</td>
                            </tr>
                            <tr>
                                <th>{t("records")}</th>
                                <td>{statistic.recordsTotal}</td>
                                <td>{`${statistic.recordsToday} (${formatSignedNumber(statistic.recordsTodayDiff)})`}</td>
                                <td>{`${statistic.recordsWeek} (${formatSignedNumber(statistic.recordsWeekDiff)})`}</td>
                                <td>{`${statistic.recordsMonth} (${formatSignedNumber(statistic.recordsMonthDiff)})`}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="details-controls d-flex justify-content-center my-2">
                        <div className="d-flex justify-content-center mt-4">
                            <Button text={t("ok")} danger={false} onClick={() => history(ADMIN_ROUTE)}/>
                        </div>
                    </div>
                </div>
            </Fragment>
        }
    </div>);
}