import React, {Fragment, useEffect, useMemo, useState} from "react";
import {Document, Page, pdfjs} from "react-pdf";
import {useTranslation} from "react-i18next";
import SvgPdfPreviousPage from "../../../assets/svg/PdfPreviousPage";
import SvgPdfNextPage from "../../../assets/svg/PdfNextPage";
import './PdfViewer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {useScreenSize} from "../../../hooks";
import Loader from "../Loader/Loader";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface PdfViewerProps {
    url: string;
}

export const PdfViewer: React.FC<PdfViewerProps> = ({url}: PdfViewerProps) => {
    const {t} = useTranslation();
    const pdfUrl = useMemo(() => ({ url }), [url])
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const screenSize = useScreenSize();
    const containerWidth = useMemo(() => {
        return Math.max(480, Math.min(744, screenSize.width * 0.85));
    }, [screenSize.width]);
    const containerHeight = useMemo(() => {
        return containerWidth * 1.4143;
    }, [containerWidth]);

    function onDocumentLoadSuccess({numPages}: any) {
        setNumberOfPages(numPages);
    }

    return (
        <div className="d-flex flex-column align-items-center my-4">
            {containerWidth > 0 && containerHeight > 0 &&
                <Fragment>
                    <div className="pdf-viewer-container d-flex flex-column align-items-center">
                        <div style={{width: `${containerWidth}px`, height: `${containerHeight}px`}}>
                            <Document file={pdfUrl} loading={<Loader/>}
                                      onLoadSuccess={onDocumentLoadSuccess}>
                                <Page pageNumber={pageNumber} width={containerWidth}
                                      height={containerHeight}/>
                            </Document>
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-center mt-2">
                        <div className={`pdf-viewer-button mx-4 ${pageNumber === 1 ? "disabled" : ""}`}
                             onClick={() => setPageNumber(Math.max(1, pageNumber - 1))}>
                            <SvgPdfPreviousPage/><span>{t("previous_page")}</span>
                        </div>
                        <span className="pdf-viewer-page-info">{t('page_number_format', {current: pageNumber, total: numberOfPages})}</span>
                        <div className={`pdf-viewer-button mx-4 ${pageNumber === numberOfPages ? "disabled" : ""}`}
                             onClick={() => setPageNumber(Math.min(numberOfPages, pageNumber + 1))}>
                            <SvgPdfNextPage/><span>{t("next_page")}</span>
                        </div>
                    </div>
                </Fragment>
            }
        </div>
    );
}

