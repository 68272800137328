import {useNavigate} from "react-router";
import {useEffect} from "react";
import {clearSavedListParams} from "../../../helpers/LocalStorageHelper";
import {SHARED_RECORDS_ROUTE} from "../../../routes";

export function SharedRecordsClear() {
    const history = useNavigate()
    useEffect(() => {
        clearSavedListParams();
        history(SHARED_RECORDS_ROUTE, {replace: true});
    }, []);
    return <div/>
}