import {MY_RECORDS_ROUTE} from "../../../routes";
import {useNavigate} from "react-router";
import {useEffect} from "react";
import {clearSavedListParams} from "../../../helpers/LocalStorageHelper";

export function MyRecordsClear() {
    const history = useNavigate()
    useEffect(() => {
        clearSavedListParams();
        history(MY_RECORDS_ROUTE, {replace: true});
    }, []);
    return <div/>
}