import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import handleErrors from "../../../helpers/ErrorHandler";
import {useAbortController, useAppDispatch} from "../../../hooks";
import {EcgData} from "../../../models/EcgData";
import {Record} from '../../../models/Record';
import ErrorResponse from "../../../models/ErrorResponse";
import * as ErrorCodes from "../../../api/ErrorCodes";
import * as ApiHelper from '../../../api/ApiHelper';
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import NotFound from "../../Widgets/NotFound/NotFound";
import {formatDateTime} from "../../../helpers/FormatHelper";
import {EcgViewer} from "../../Widgets/EcgViewer/EcgViewer";
import {ecgTestData} from "../../../ecg/EcgTest";
import {EcgDetailsTable} from "../../Widgets/EcgDetailsTable/EcgDetailsTable";
import {Conclusion} from "../../Widgets/Conclusion/Conclusion";
import {PatientInfo} from "../../Widgets/PatientInfo/PatientInfo";
import {logError} from "../../../helpers/LogHelper";


export const GuestEcgView: React.FC = () => {
    let {token = ""} = useParams();
    const [controller] = useAbortController();
    const dispatch = useAppDispatch();
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [record, setRecord] = useState(null as Record | null);
    const [initialConclusion, setInitialConclusion] = useState(null as null | string);
    const [data, setData] = useState(null as EcgData | null);
    const dataHandler = (data: EcgData) => {
        setFetchingState(false);
        setErrorState(false);
        setNotFoundState(false);
        setData(data);
    };
    const recordHandler = (record: Record) => {
        setErrorState(false);
        setNotFoundState(false);
        setRecord(record);
        setInitialConclusion(record.ecgStudy.ecgConclusion ?? "");
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Ecg data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
            setFetchingState(false);
        }
    };
    const fetchData = (record: Record) => {
        if (process.env.NODE_ENV === 'development') {
            //For testing
            dataHandler(ecgTestData);
        } else {
            //Main code
            if (record.ecgStudy) {
                setFetchingState(true);
                ApiHelper.getEcgDataWithToken(token, record.ecgStudy.ecgId, controller, dataHandler, errorHandler);
            } else {
                setErrorState(true);
            }
        }
    };
    const fetchRecord = () => {
        setFetchingState(true);
        ApiHelper.getRecordWithToken(token, controller, recordHandler, errorHandler);
    };
    useEffect(() => fetchRecord(), [token]);     // eslint-disable-line
    useEffect(() => {
        if (record) {
            fetchData(record);
        }
    }, [record]);       // eslint-disable-line
    const isOk = !hasError && !notFound && !isFetching;
    return (
        <div>
            <Breadcrumbs
                data={new Map([[token, record ? formatDateTime(record.dateTime) : null], [record?.patientId ?? "_", record?.patientName ?? null]])}/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchRecord}/>}
            {!isFetching && notFound && <NotFound/>}
            {(isOk && record && data) &&
                <div className="ecg-data-container">
                    <PatientInfo record={record}/>
                    <EcgViewer ecgData={data} recordId={record.id} studyId={record.ecgStudy.ecgId} isSupport={false}/>
                </div>
            }
            {(isOk && record && data) &&
                <EcgDetailsTable record={record} isSupport={false} data={data}/>
            }
            {(isOk && record) &&
                <Conclusion initialValue={initialConclusion ?? ""} cachedValue={initialConclusion ?? ""}/>}
        </div>
    );
};

export default GuestEcgView;