import React from "react";
import {Route, Routes} from "react-router-dom";
import Reset from "./Reset/Reset";
import Restore from "./Restore/Restore";
import SignIn from "./SignIn/SignIn";
import * as AppRoutes from "../../routes";
import Blocked from "./Blocked/Blocked";
import NotConfirmed from "./EmailNotConfirmed/NotConfirmed";
import NotFound from "../Widgets/NotFound/NotFound";
import Footer from "../Widgets/Footer/Footer";
import {Container} from "react-bootstrap";

function Account() {
    return (
        <Container fluid>
            <Routes>
                <Route path={AppRoutes.SIGN_IN_ROUTE} element={<SignIn isSignUp={false}/>}/>
                <Route path={AppRoutes.SIGN_UP_ROUTE} element={<SignIn isSignUp={true}/>}/>
                <Route path={AppRoutes.RESTORE_ROUTE} element={<Restore/>}/>
                <Route path={AppRoutes.RESET_ROUTE} element={<Reset/>}/>
                <Route path={AppRoutes.BLOCKED_ROUTE} element={<Blocked/>}/>
                <Route path={AppRoutes.NOT_CONFIRMED_ROUTE} element={<NotConfirmed/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
            <Footer/>
        </Container>
    );
}

export default Account;