import React, {useEffect} from 'react';
import Home from './components/Home/Home';
import * as AppRoutes from './routes';
import {Route, Routes} from 'react-router-dom';
import Account from './components/Account/Account';
import {Device} from "./components/Device/Device";
import {useViewportScale} from "./hooks";
import {MobileApp} from "./components/MobileApp/MobileApp";
import Guest from "./components/Guest/Guest";
import {cleanByTime} from "./local_cache/CacheStorage";
import {logInfo} from "./helpers/LogHelper";

function App() {
    useEffect(() => {
        cleanByTime().then();
    }, []);
    useEffect(() => {
        logInfo("Starting");
    }, []);
    useViewportScale(480);
    return (
        <Routes>
            <Route path={`${AppRoutes.DEVICE_ROUTE}/:deviceClass/:deviceNumber`} element={<Device/>}/>
            <Route path={AppRoutes.MOBILE_APP_ROUTE} element={<MobileApp/>}/>
            <Route path={`${AppRoutes.ACCOUNT_ROUTE}/*`} element={<Account/>}/>
            <Route path={`${AppRoutes.GUEST_ROUTE}/*`} element={<Guest/>}/>
            <Route path="*" element={<Home/>}/>
        </Routes>
    );
}

export default App;
