import {UserInfo} from "../../../models/UserInfo";
import {getUserPhotoUrl} from "../../../api/ApiHelper";
import {NavLink} from "react-router-dom";
import React, {useState} from "react";
import * as AppRoutes from "../../../routes";
import HeaderLogo from "./HeaderLogo";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import {useTranslation} from "react-i18next";
import {Menu} from "@mui/icons-material";
import {Avatar} from "@mui/material";

interface HeaderShortProps{
    links: {text: string, path: string}[];
    userInfo : UserInfo | null;
    handleProfileClick: () => void;
    handleSettingsClick: () => void;
    handleLogOutClick: () => void;
}

export  const HeaderShort = ({links, userInfo, handleProfileClick, handleLogOutClick} : HeaderShortProps) => {
    const {t} = useTranslation();
    const photoUrl = getUserPhotoUrl(userInfo?.photoUrl);
    const [isExpanded, setExpanded] = useState(false);
    const handleToggleClick = () => {
        setExpanded(!isExpanded);
    }
    const handleLinkClick = () => {
        if (isExpanded) {
            setExpanded(false);
        }
    };
    const navLinkClassName = "drawer-link";
    const navLinks = links.map(link => <NavLink key={link.path} className={`${navLinkClassName} disable-select`} to={link.path} onClick={handleLinkClick}>{link.text}</NavLink>)
    return (
        <div className="header">
            <NavLink className="drawer-logo" to={AppRoutes.HOME_ROUTE}><HeaderLogo /></NavLink>
            <div className="drawer-toggle" onClick={handleToggleClick}><Menu /></div>
            <div className={`drawer ${isExpanded ? '' : 'hidden'}`}>
                <div className="drawer-user-info">
                    <div className="drawer-avatar">
                        <Avatar className="drawer-avatar-img" alt={userInfo?.name} src={photoUrl} onClick={handleProfileClick} />
                    </div>
                    <div className="drawer-profile-link" onClick={handleProfileClick}>{userInfo?.name ?? ""}</div>
                </div>
                <NavLink className="drawer-link disable-select" to={AppRoutes.PROFILE_ROUTE} onClick={handleLinkClick}>{t("profile")}</NavLink>
                <NavLink className="drawer-link disable-select" to={AppRoutes.SETTINGS_ROUTE} onClick={handleLinkClick}>{t("settings")}</NavLink>
                <NavLink className="drawer-link disable-select" to={"logout"} onClick={handleLogOutClick}>{t("sign_out")}</NavLink>
                <div className="drawer-divider" />
                {navLinks}
                <div className="drawer-divider" />
                <div className="drawer-options-dropdown">
                    <div className="title disable-select">{t("language")}:</div>
                    <LanguageDropdown />
                </div>
            </div>
        </div>
    );
}