import React from "react";
import { useNavigate } from "react-router-dom";
import { USERS_ROUTE } from "../../../routes";
import { DATA_LIST_PAGE_SIZE } from "../../../settings";
import { UsersList } from "../../Widgets/UsersList/UsersList";
import { Breadcrumbs } from "../../Widgets/Breadcrumbs/Breadcrumbs";

function Users() {
    const history = useNavigate();
    const userClickHandler = (id: string) => {
        history(`${USERS_ROUTE}/${id}`);
    };
    return (
        <div>
            <Breadcrumbs />
            <UsersList tag="list_users" pageSize={DATA_LIST_PAGE_SIZE} userClickHandler={userClickHandler} />
        </div>
    );
}

export default Users;