import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {SpiroData} from "../../../models/SpiroData";
import numeral from "numeral";
import './SpiroViewer.css';
import {ZScoreView} from "./ZScoreView";

interface Props {
    data: SpiroData;
    activeRecordIndex: number;

    isSupport: boolean;
}

export function SpiroResultsTable(props: Props) {
    const {t} = useTranslation();
    const predictionSystemFormatter = (predictionSystem?: number): string => {
        switch (predictionSystem) {
            case 1:
                return t("spiro_prediction_system_gli2012");
            case 2:
                return t("spiro_prediction_system_nhanes_iii");
            default:
                return "";
        }
    };
    let idx;
    if (props.activeRecordIndex > 0) {
        idx = props.activeRecordIndex - 1;
    } else {
        let i = 0;
        idx = 0;
        let fvc = Number.MIN_VALUE;
        for (const record of props.data.records) {
            if (record.result.fvcResult) {
                if (record.result.fvcResult.fvc.measured > fvc) {
                    fvc = record.result.fvcResult.fvc.measured;
                    idx = i;
                }
            }
            i++;
        }
    }
    const result = props.data.records[idx].result;
    const btps = props.data.records[idx].btpsData;
    return (
        <div className="d-flex flex-column justify-content-center my-4 mx-4">
            <div className="result-table">
                <table>
                    <tbody>
                    <tr>
                        <th/>
                        <th>{t("spiro_value")}</th>
                        <th>{t("spiro_predicted")}</th>
                        <th>{t("spiro_predicted_ratio")}</th>
                        <th>{t("spiro_lln")}</th>
                        <th>{t("spiro_z_score")}</th>
                        <th/>
                        <th>{t("spiro_prediction_system")}</th>
                    </tr>
                    {result.fvcResult?.fvc &&
                        <tr>
                            <th className={"font-weight-bold"}>{t('spiro_fvc')}</th>
                            <td className={"font-weight-bold"}>{numeral(result.fvcResult.fvc.measured).format("0.00")}</td>
                            {result.fvcResult.fvc.prSystem ?
                                <Fragment>
                                    <td className={"font-weight-bold"}>{numeral(result.fvcResult.fvc.predicted).format("0.00")}</td>
                                    <td className={"font-weight-bold"}>{numeral(result.fvcResult.fvc.percentPredicted).format("0.00")}</td>
                                    <td className={"font-weight-bold"}>{numeral(result.fvcResult.fvc.lln).format("0.00")}</td>
                                    <td className={"font-weight-bold"}>{numeral(result.fvcResult.fvc.zScore).format("0.00")}</td>
                                    <td>{result.fvcResult.fvc.prSystem &&
                                        <ZScoreView zScore={result.fvcResult.fvc.zScore}/>}</td>
                                    <td className={"font-weight-bold"}>{predictionSystemFormatter(result.fvcResult.fvc.prSystem)}</td>
                                </Fragment> : <td/>
                            }
                        </tr>
                    }
                    {result.fvcResult?.fev1 &&
                        <tr>
                            <th className={"font-weight-bold"}>{t('spiro_fev1')}</th>
                            <td className={"font-weight-bold"}>{numeral(result.fvcResult.fev1.measured).format("0.00")}</td>
                            {result.fvcResult.fev1.prSystem ?
                                <Fragment>
                                    <td className={"font-weight-bold"}>{numeral(result.fvcResult.fev1.predicted).format("0.00")}</td>
                                    <td className={"font-weight-bold"}>{numeral(result.fvcResult.fev1.percentPredicted).format("0.00")}</td>
                                    <td className={"font-weight-bold"}>{numeral(result.fvcResult.fev1.lln).format("0.00")}</td>
                                    <td className={"font-weight-bold"}>{numeral(result.fvcResult.fev1.zScore).format("0.00")}</td>
                                    <td>{result.fvcResult.fev1.prSystem &&
                                        <ZScoreView zScore={result.fvcResult.fev1.zScore}/>}</td>
                                    <td className={"font-weight-bold"}>{predictionSystemFormatter(result.fvcResult.fev1.prSystem)}</td>
                                </Fragment> : <td/>
                            }
                        </tr>
                    }
                    {result.fvcResult?.fev1Fvc &&
                        <tr>
                            <th className={"font-weight-bold"}>{t('spiro_fev1_fvc')}</th>
                            <td className={"font-weight-bold"}>{numeral(result.fvcResult.fev1Fvc.measured).format("0.00")}</td>
                            {result.fvcResult.fev1Fvc.prSystem ?
                                <Fragment>
                                    <td className={"font-weight-bold"}>{numeral(result.fvcResult.fev1Fvc.predicted).format("0.00")}</td>
                                    <td className={"font-weight-bold"}>{numeral(result.fvcResult.fev1Fvc.percentPredicted).format("0.00")}</td>
                                    <td className={"font-weight-bold"}>{numeral(result.fvcResult.fev1Fvc.lln).format("0.00")}</td>
                                    <td className={"font-weight-bold"}>{numeral(result.fvcResult.fev1Fvc.zScore).format("0.00")}</td>
                                    <td>{result.fvcResult.fev1Fvc.prSystem &&
                                        <ZScoreView zScore={result.fvcResult.fev1Fvc.zScore}/>}</td>
                                    <td className={"font-weight-bold"}>{predictionSystemFormatter(result.fvcResult.fev1Fvc.prSystem)}</td>
                                </Fragment> : <td/>
                            }
                        </tr>
                    }
                    {result.fvcResult?.pef &&
                        <tr>
                            <th className={"font-weight-bold"}>{t('spiro_pef')}</th>
                            <td className={"font-weight-bold"}>{numeral(result.fvcResult.pef.measured).format("0.00")}</td>
                            {result.fvcResult.pef.prSystem ?
                                <Fragment>
                                    <td className={"font-weight-bold"}>{numeral(result.fvcResult.pef.predicted).format("0.00")}</td>
                                    <td className={"font-weight-bold"}>{numeral(result.fvcResult.pef.percentPredicted).format("0.00")}</td>
                                    <td className={"font-weight-bold"}>{numeral(result.fvcResult.pef.lln).format("0.00")}</td>
                                    <td className={"font-weight-bold"}>{numeral(result.fvcResult.pef.zScore).format("0.00")}</td>
                                    <td>{result.fvcResult.pef.prSystem &&
                                        <ZScoreView zScore={result.fvcResult.pef.zScore}/>}</td>
                                    <td className={"font-weight-bold"}>{predictionSystemFormatter(result.fvcResult.pef.prSystem)}</td>
                                </Fragment> : <td/>
                            }
                        </tr>
                    }
                    {result.fvcResult?.fev0_75 &&
                        <tr>
                            <th>{t('spiro_fev0_75')}</th>
                            <td>{numeral(result.fvcResult.fev0_75.measured).format("0.00")}</td>
                            {result.fvcResult.fev0_75.prSystem ?
                                <Fragment>
                                    <td>{numeral(result.fvcResult.fev0_75.predicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev0_75.percentPredicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev0_75.lln).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev0_75.zScore).format("0.00")}</td>
                                    <td>{result.fvcResult.fev0_75.prSystem &&
                                        <ZScoreView zScore={result.fvcResult.fev0_75.zScore}/>}</td>
                                    <td>{predictionSystemFormatter(result.fvcResult.fev0_75.prSystem)}</td>
                                </Fragment> : <td/>
                            }
                        </tr>
                    }
                    {result.fvcResult?.fev0_75Fvc &&
                        <tr>
                            <th>{t('spiro_fev0_75_fvc')}</th>
                            <td>{numeral(result.fvcResult.fev0_75Fvc.measured).format("0.00")}</td>
                            {result.fvcResult.fev0_75Fvc.prSystem ?
                                <Fragment>
                                    <td>{numeral(result.fvcResult.fev0_75Fvc.predicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev0_75Fvc.percentPredicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev0_75Fvc.lln).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev0_75Fvc.zScore).format("0.00")}</td>
                                    <td>{result.fvcResult.fev0_75Fvc.prSystem &&
                                        <ZScoreView zScore={result.fvcResult.fev0_75Fvc.zScore}/>}</td>
                                    <td>{predictionSystemFormatter(result.fvcResult.fev0_75Fvc.prSystem)}</td>
                                </Fragment> : <td/>
                            }
                        </tr>
                    }
                    {result.fvcResult?.fef25 &&
                        <tr>
                            <th>{t('spiro_fef25')}</th>
                            <td>{numeral(result.fvcResult.fef25.measured).format("0.00")}</td>
                            {result.fvcResult.fef25.prSystem ?
                                <Fragment>
                                    <td>{numeral(result.fvcResult.fef25.predicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fef25.percentPredicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fef25.lln).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fef25.zScore).format("0.00")}</td>
                                    <td>{result.fvcResult.fef25.prSystem &&
                                        <ZScoreView zScore={result.fvcResult.fef25.zScore}/>}</td>
                                    <td>{predictionSystemFormatter(result.fvcResult.fef25.prSystem)}</td>
                                </Fragment> : <td/>
                            }
                        </tr>
                    }
                    {result.fvcResult?.fef50 &&
                        <tr>
                            <th>{t('spiro_fef50')}</th>
                            <td>{numeral(result.fvcResult.fef50.measured).format("0.00")}</td>
                            {result.fvcResult.fef50.prSystem ?
                                <Fragment>
                                    <td>{numeral(result.fvcResult.fef50.predicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fef50.percentPredicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fef50.lln).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fef50.zScore).format("0.00")}</td>
                                    <td>{result.fvcResult.fef50.prSystem &&
                                        <ZScoreView zScore={result.fvcResult.fef50.zScore}/>}</td>
                                    <td>{predictionSystemFormatter(result.fvcResult.fef50.prSystem)}</td>
                                </Fragment> : <td/>
                            }
                        </tr>
                    }
                    {result.fvcResult?.fef75 &&
                        <tr>
                            <th>{t('spiro_fef75')}</th>
                            <td>{numeral(result.fvcResult.fef75.measured).format("0.00")}</td>
                            {result.fvcResult.fef75.prSystem ?
                                <Fragment>
                                    <td>{numeral(result.fvcResult.fef75.predicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fef75.percentPredicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fef75.lln).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fef75.zScore).format("0.00")}</td>
                                    <td>{result.fvcResult.fef75.prSystem &&
                                        <ZScoreView zScore={result.fvcResult.fef75.zScore}/>}</td>
                                    <td>{predictionSystemFormatter(result.fvcResult.fef75.prSystem)}</td>
                                </Fragment> : <td/>
                            }
                        </tr>
                    }
                    {result.fvcResult?.fef2575 &&
                        <tr>
                            <th>{t('spiro_fef2575')}</th>
                            <td>{numeral(result.fvcResult.fef2575.measured).format("0.00")}</td>
                            {result.fvcResult.fef2575.prSystem ?
                                <Fragment>
                                    <td>{numeral(result.fvcResult.fef2575.predicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fef2575.percentPredicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fef2575.lln).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fef2575.zScore).format("0.00")}</td>
                                    <td>{result.fvcResult.fef2575.prSystem &&
                                        <ZScoreView zScore={result.fvcResult.fef2575.zScore}/>}</td>
                                    <td>{predictionSystemFormatter(result.fvcResult.fef2575.prSystem)}</td>
                                </Fragment> : <td/>
                            }
                        </tr>
                    }
                    {result.fvcResult?.fev2 &&
                        <tr>
                            <th>{t('spiro_fev2')}</th>
                            <td>{numeral(result.fvcResult.fev2.measured).format("0.00")}</td>
                            {result.fvcResult.fev2.prSystem ?
                                <Fragment>
                                    <td>{numeral(result.fvcResult.fev2.predicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev2.percentPredicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev2.lln).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev2.zScore).format("0.00")}</td>
                                    <td>{result.fvcResult.fev2.prSystem &&
                                        <ZScoreView zScore={result.fvcResult.fev2.zScore}/>}</td>
                                    <td>{predictionSystemFormatter(result.fvcResult.fev2.prSystem)}</td>
                                </Fragment> : <td/>
                            }
                        </tr>
                    }
                    {result.fvcResult?.fev3 &&
                        <tr>
                            <th>{t('spiro_fev3')}</th>
                            <td>{numeral(result.fvcResult.fev3.measured).format("0.00")}</td>
                            {result.fvcResult.fev3.prSystem ?
                                <Fragment>
                                    <td>{numeral(result.fvcResult.fev3.predicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev3.percentPredicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev3.lln).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev3.zScore).format("0.00")}</td>
                                    <td>{result.fvcResult.fev3.prSystem &&
                                        <ZScoreView zScore={result.fvcResult.fev3.zScore}/>}</td>
                                    <td>{predictionSystemFormatter(result.fvcResult.fev3.prSystem)}</td>
                                </Fragment> : <td/>
                            }
                        </tr>
                    }
                    {result.fvcResult?.fev6 &&
                        <tr>
                            <th>{t('spiro_fev6')}</th>
                            <td>{numeral(result.fvcResult.fev6.measured).format("0.00")}</td>
                            {result.fvcResult.fev6.prSystem ?
                                <Fragment>
                                    <td>{numeral(result.fvcResult.fev6.predicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev6.percentPredicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev6.lln).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev6.zScore).format("0.00")}</td>
                                    <td>{result.fvcResult.fev6.prSystem &&
                                        <ZScoreView zScore={result.fvcResult.fev6.zScore}/>}</td>
                                    <td>{predictionSystemFormatter(result.fvcResult.fev6.prSystem)}</td>
                                </Fragment> : <td/>
                            }
                        </tr>
                    }
                    {result.fvcResult?.fev1Fev6 &&
                        <tr>
                            <th>{t('spiro_fev1_fev6')}</th>
                            <td>{numeral(result.fvcResult.fev1Fev6.measured).format("0.00")}</td>
                            {result.fvcResult.fev1Fev6.prSystem ?
                                <Fragment>
                                    <td>{numeral(result.fvcResult.fev1Fev6.predicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev1Fev6.percentPredicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev1Fev6.lln).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fev1Fev6.zScore).format("0.00")}</td>
                                    <td>{result.fvcResult.fev1Fev6.prSystem &&
                                        <ZScoreView zScore={result.fvcResult.fev1Fev6.zScore}/>}</td>
                                    <td>{predictionSystemFormatter(result.fvcResult.fev1Fev6.prSystem)}</td>
                                </Fragment> : <td/>
                            }
                        </tr>
                    }
                    {result.fvcResult?.pefTime &&
                        <tr>
                            <th>{t('spiro_pef_time')}</th>
                            <td>{numeral(result.fvcResult.pefTime.measured).format("0.00")}</td>
                            {result.fvcResult.pefTime.prSystem ?
                                <Fragment>
                                    <td>{numeral(result.fvcResult.pefTime.predicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.pefTime.percentPredicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.pefTime.lln).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.pefTime.zScore).format("0.00")}</td>
                                    <td>{result.fvcResult.pefTime.prSystem &&
                                        <ZScoreView zScore={result.fvcResult.pefTime.zScore}/>}</td>
                                    <td>{predictionSystemFormatter(result.fvcResult.pefTime.prSystem)}</td>
                                </Fragment> : <td/>
                            }
                        </tr>
                    }
                    {result.fvcResult?.fet &&
                        <tr>
                            <th>{t('spiro_fet')}</th>
                            <td>{numeral(result.fvcResult.fet.measured).format("0.00")}</td>
                            {result.fvcResult.fet.prSystem ?
                                <Fragment>
                                    <td>{numeral(result.fvcResult.fet.predicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fet.percentPredicted).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fet.lln).format("0.00")}</td>
                                    <td>{numeral(result.fvcResult.fet.zScore).format("0.00")}</td>
                                    <td>{result.fvcResult.fet.prSystem &&
                                        <ZScoreView zScore={result.fvcResult.fet.zScore}/>}</td>
                                    <td>{predictionSystemFormatter(result.fvcResult.fet.prSystem)}</td>
                                </Fragment> : <td/>
                            }
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
            {btps &&
                <div className='align-self-start interpretation-text'>
                    {`${t('spiro_btps_data', {
                        temperature: numeral(btps.temperature).format("0"),
                        pressure: numeral(btps.pressure).format("0")
                    })} ${props.isSupport ? `${t("spiro_correction_coefficient")}: ${numeral(props.data.records[idx].correctionCoefficient ?? 1).format("0.0000")}` : ""}`}
                </div>
            }
        </div>
    );
}