import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAbortController, useAppDispatch, useAppSelector} from "../../../hooks";
import {selectToken} from "../../../features/account/accountSlice";
import PagedData from "../../../models/PagedData";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import * as ApiHelper from "../../../api/ApiHelper";
import AsyncIndicator from "../AsyncIndicator/AsyncIndicator";
import {FetchError} from "../FetchError/FetchError";
import {UserAccessData} from "../../../models/UserAccessData";
import {ListPagination} from "../Pagination/ListPagination";
import UserAccess from "../UserAccess/UserAccess";
import UserAccessPlaceholder from "../UserAccessPlaceholder/UserAccessPlaceholder";
import {logError} from "../../../helpers/LogHelper";
import {Divider} from "@mui/material";

interface UserAccessListProps {
    id: string;
    isAppointment: boolean;
    pageSize: number;
    userClickHandler: (data: UserAccessData) => void;
}

export const UserAccessList: React.FC<UserAccessListProps> = ({
                                                                  id,
                                                                  isAppointment,
                                                                  pageSize,
                                                                  userClickHandler
                                                              }: UserAccessListProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [controller] = useAbortController();
    const token = useAppSelector(selectToken);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [users, setUsers] = useState([] as UserAccessData[]);
    const paginationClickHandler = (page: number) => {
        setFetchingState(true);
        setCurrentPage(page);
    };
    const usersHandler = (pagedData: PagedData<UserAccessData>) => {
        setFetchingState(false);
        setErrorState(false);
        setUsers(pagedData.data);
        setCurrentPage(pagedData.page);
        setTotalPages(pagedData.totalPages);
        setTotalRecords(pagedData.totalRecords);
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Access data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            setErrorState(true);
            setFetchingState(false);
        }
    };
    const fetchUsers = (page: number) => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            if (isAppointment) {
                ApiHelper.getUsersBySharedRecord(userToken, id, page, pageSize, controller, usersHandler, errorHandler);
            } else {
                ApiHelper.getUsersBySharedPatient(userToken, id, page, pageSize, controller, usersHandler, errorHandler);
            }
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchUsers(currentPage), [currentPage]);     // eslint-disable-line
    if (isFetching) {
        return (
            <AsyncIndicator/>
        );
    }
    if (hasError) {
        return (
            <FetchError onRetry={() => fetchUsers(currentPage)}/>
        );
    }
    const userComponents = users.map(data => <UserAccess key={data.id} data={data} showType={true}
                                                         onClick={() => userClickHandler(data)}/>)
    const hasData = userComponents.length > 0;
    return (
        <div className="data-list-container">
            <div className="data-list">
                {(hasData) ?
                    (
                        <Fragment>
                            <div className="data-list-title">{t("granted_users")}: <span>{totalRecords}</span></div>
                            <Divider/>
                            <table>
                                <thead>
                                <tr className="table-header">
                                    <th>{t("name")}</th>
                                    <th>{t("email")}</th>
                                    <th>{t("type")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {userComponents}
                                </tbody>
                            </table>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="data-list-title">{t("granted_users")}</div>
                            <Divider/>
                            <UserAccessPlaceholder className="my-4"/>
                        </Fragment>
                    )
                }
            </div>
            {totalPages > 1 &&
            <ListPagination page={currentPage} totalPages={totalPages} clickHandler={paginationClickHandler}/>}
        </div>
    );
}