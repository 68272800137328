import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useAbortController, useAppSelector} from "../../../../hooks";
import {selectToken} from "../../../../features/account/accountSlice";
import React, {Fragment, useEffect, useState} from "react";
import {User} from "../../../../models/User";
import ErrorResponse from "../../../../models/ErrorResponse";
import {logError} from "../../../../helpers/LogHelper";
import * as ApiHelper from "../../../../api/ApiHelper";
import {Breadcrumbs} from "../../../Widgets/Breadcrumbs/Breadcrumbs";
import UsersLong from "../../../Widgets/UsersLong/UsersLong";
import {ADMIN_ROUTE, USERS_ROUTE} from "../../../../routes";
import Loader from "../../../Widgets/Loader/Loader";
import {FetchError} from "../../../Widgets/FetchError/FetchError";
import UsersPlaceholder from "../../../Widgets/UsersPlaceholder/UsersPlaceholder";
import {Button} from "../../../Widgets/Button/Button";

interface SelectableUser {
    isChecked: boolean;
    user: User
}

function RemoveInactiveUsers() {
    const {t} = useTranslation();
    const history = useNavigate();
    const token = useAppSelector(selectToken);
    const [controller] = useAbortController();
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [users, setUsers] = useState(new Array<SelectableUser>());
    const usersHandler = (users: Array<User>) => {
        setFetchingState(false);
        setErrorState(false);
        setUsers(users.map(u => {
            return {
                isChecked: true,
                user: u
            } as SelectableUser;
        }));
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Users data fetch error", error);
        setErrorState(true);
        setFetchingState(false);
    };
    const fetchUsers = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.searchInactiveUsers(userToken, controller, usersHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    const deleteUsers = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            const ids = users.filter(u => u.isChecked).map(u => u.user.id);
            ApiHelper.deleteInactiveUsers(userToken, ids, controller, usersHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const userClickHandler = (id: string) => {
        window.open(`${USERS_ROUTE}/${id}`, '_blank');
    };
    const userCheckHandler = (user: SelectableUser, value: boolean) => {
        user.isChecked = value;
        setUsers(users.map(u => u));
    }
    const userComponents = users.map(user => <UsersLong key={user.user.id} user={user.user}
                                                        onClick={() => userClickHandler(user.user.id)}
                                                        isChecked={user.isChecked}
                                                        onCheck={(value) => userCheckHandler(user, value)}/>)
    const hasData = userComponents.length > 0;
    return (<div>
        <Breadcrumbs/>
        {isFetching && <Loader/>}
        {!isFetching && hasError &&
            <FetchError onRetry={() => fetchUsers()}/>
        }
        {!isFetching && !hasError &&
            <Fragment>
                <div className="data-list-container">
                    <div className="data-list">
                        {hasData ? (<Fragment>
                            <div className="data-list-title">{t("inactive_users_count_prompt")}: <span>{users.length}</span></div>
                            <table>
                                <thead>
                                <tr className="table-header">
                                    <th/>
                                    <th>{t("email")}</th>
                                    <th>{t("role")}</th>
                                    <th>{t("name")}</th>
                                    <th>{t("registration_date")}</th>
                                    <th>{t("email_status")}</th>
                                    <th>{t("account_status")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {userComponents}
                                </tbody>
                            </table>
                        </Fragment>) : (<UsersPlaceholder className="my-4"/>)}
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="details-controls d-flex justify-content-center my-2">
                        {hasData &&
                            <div>
                                <Button text={t("delete")} className="mt-4 mr-2" danger={true} onClick={deleteUsers}/>
                            </div>
                        }
                        <div className="d-flex justify-content-center mt-4">
                            <Button text={t("ok")} danger={false} onClick={() => history(ADMIN_ROUTE)}/>
                        </div>
                    </div>
                </div>
            </Fragment>
        }
    </div>);
}

export default RemoveInactiveUsers;