import {useTranslation} from "react-i18next";
import {EcgInterpretationReport} from "../../../models/EcgInterpretationReport";
import React, {Fragment, useMemo} from "react";
import {formatDateTime} from "../../../helpers/FormatHelper";
import {RenderEcgInterpretationReport} from "../../../helpers/EcgReportRenderer";
import {EcgInterpretationLocalization} from "../../../locale/EcgInterpretationData";
import {Button} from "../Button/Button";

interface Props {
    isSupport: boolean;
    data: EcgInterpretationReport | null;
    interpretationRequest?: () => void;
}

export function EcgInterpretationTable(props: Props) {
    const renderedReport = useMemo(() => {
        if (props.data) {
            return RenderEcgInterpretationReport(props.data, "ru");
        } else {
            return null;
        }
    }, [props.data]);
    const {t} = useTranslation();
    const analyze = () => {
        if (props.interpretationRequest) {
            props.interpretationRequest();
        }
    }
    return (
        <div className="d-flex flex-column justify-content-between">
            <div className="calculation-table-title text-center">{t("interpretation")}</div>
            <div className="d-flex justify-content-center my-4">
                <div className="details-table">
                    <table>
                        <tbody>
                        {!renderedReport &&
                            <Fragment>
                                <tr>
                                    <td colSpan={2}></td>
                                </tr>
                            </Fragment>
                        }
                        {renderedReport && <Fragment>
                            {props.isSupport &&
                                <Fragment>
                                    <tr>
                                        <th>{t("ecg_solver_version")}</th>
                                        <td>{renderedReport.SolverVersion}</td>
                                    </tr>
                                    <tr>
                                        <th>{t("ecg_solver_timestamp")}</th>
                                        <td>{formatDateTime(renderedReport.Timestamp)}</td>
                                    </tr>
                                </Fragment>
                            }
                            {renderedReport.Interpretations.map(group => (
                                <tr key={group.Group}>
                                    <th>
                                        {group.Group}
                                    </th>
                                    <td>
                                        {group.Interpretation}
                                    </td>
                                </tr>
                            ))}
                        </Fragment>}
                        <tr>
                            <td colSpan={2}>
                                <div className="d-flex justify-content-center mt-4 mb-2">
                                    <Button className="mx-2" text={t(props.data ? "repeat_interpretation" : "do_interpretation")} onClick={analyze}/>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}